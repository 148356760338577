const footerStyles = {
  footer: {
    minHeight: '80px',
    bottom: 0,
    padding: '0px 24px 0px 24px',
    color: '#fff',
    background: (theme) => theme.palette.primary.main,
    position: 'relative',
  },
  infoText: {
    fontSize: (theme) => (theme.breakpoints.down('sm') ? '13px' : '20px'),
  },
};

export default footerStyles;
