import moment from 'moment/moment';

const getViewsData = ({
  controls,
  selectedRegion,
  selectedWorkshop,
  selectedVehicleModels,
  technicianData,
  serviceValidation,
  kilometerValue,
}) => [
  {
    title: 'Agendamiento',
    icon: 'CalendarTodayIcon',
    render: true,
    content: [
      { label: 'Fecha', value: moment(controls.selectedDate).format('L') },
      { label: 'Región', value: selectedRegion?.name },
      { label: 'Hora', value: controls.selectedHour },
      { label: 'Taller', value: selectedWorkshop?.name },
      { label: 'Técnico', value: technicianData?.name },
    ],
  },
  {
    title: 'Datos vehículo',
    icon: 'DirectionsCarFilledIcon',
    render: true,
    content: [
      { label: 'Marca', value: controls.brand },
      { label: 'Patente', value: controls.patent },
      { label: 'Modelo', value: selectedVehicleModels?.name },
      { label: 'Año', value: controls.year },
      { label: 'VIN', value: controls.vin },
    ],
  },
  {
    title: 'Dueño vehículo',
    icon: 'AccountCircleIcon',
    render: true,
    content: [
      { label: 'RUT', value: controls.rut },
      { label: 'Nombre', value: controls.name },
      { label: 'Apellido', value: controls.lastName },
      { label: 'Correo', value: controls.email },
      { label: 'Teléfono', value: controls.phone },
    ],
  },
  {
    title: 'Datos contacto',
    icon: 'AccountCircleIcon',
    render: controls.selectedContact !== '',
    content: [
      { label: 'Nombre', value: controls.contactName },
      { label: 'Apellido', value: controls.contactLastName },
      { label: 'Correo', value: controls.contactEmail },
      { label: 'Teléfono', value: controls.contactPhone },
    ],
  },
  {
    title: 'Servicio',
    icon: 'BuildOutlinedIcon',
    render: true,
    content: [
      { label: 'Servicio', value: serviceValidation.text },
      { label: 'KM', value: kilometerValue },
      { label: 'Falla', value: serviceValidation.data[controls.serviceType]?.name },
    ],
  },
  {
    title: 'Notas del servicio',
    icon: 'InsertDriveFileOutlinedIcon',
    render: true,
    content: [
      {
        label: 'Notas',
        value: controls.serviceNotes,
      },
    ],
  },
];

export default getViewsData;
