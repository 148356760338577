const BASE = '[REGISTER]';

const RegisterTypes = {
  SET_CONTROLS: `${BASE} SET_CONTROLS`,
  SET_CONTROL: `${BASE} SET_CONTROL`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
  CREATE_USER: `${BASE} CREATE_USER`,
  CREATE_USER_SUCCESS: `${BASE} CREATE_USER_SUCCESS`,
  CREATE_USER_FAIL: `${BASE} CREATE_USER_FAIL`,
};

export default RegisterTypes;
