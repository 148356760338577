import React from 'react';

/* React Router */
import { Navigate } from 'react-router-dom';

function Auth({ user, children }) {
  if (user) {
    return children;
  }

  return <Navigate to="/login" />;
}

export default Auth;
