import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Autocomplete, TextField } from '@mui/material';
import { darkerGreyOnForms } from 'utils/helper';

function CustomAutoComplete(props) {
  const { name, value, options, disabled, size, label, error, getNewValue, sx, handleOnBlur } =
    props;

  const handleChange = (event, newValue, reason) =>
    getNewValue({ name, id: reason === 'clear' ? '' : newValue.id });

  const currentValue = options?.find((item) => item.id.toString() === value) ?? null;

  return (
    <Autocomplete
      slotProps={{ paper: { sx: { marginBottom: 1 } } }}
      value={currentValue}
      onChange={handleChange}
      onBlur={handleOnBlur}
      sx={sx}
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      size={size}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{
            sx: { ...darkerGreyOnForms },
          }}
          error={error}
        />
      )}
      noOptionsText="NO HAY DATOS DISPONIBLES."
    />
  );
}

CustomAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  getNewValue: PropTypes.func,
  sx: PropTypes.shape({}),
  handleOnBlur: PropTypes.func,
};

CustomAutoComplete.defaultProps = {
  name: '',
  value: '',
  options: [{ id: '1', name: 'test' }],
  disabled: false,
  size: 'small',
  label: '',
  error: false,
  getNewValue: () => {},
  sx: {},
  handleOnBlur: () => {},
};

export default CustomAutoComplete;
