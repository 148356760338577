import React, { useEffect } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import Grid from '@mui/material/Grid';

/* Project */
import Steps from 'content/features/steps/Steps';
import StyledContainer from 'content/containers/StyledContainer';
import DateForm from 'content/features/schedulerSteps/DateForm';
import ResumeView from 'content/features/schedulerSteps/ResumeView';
import SelectReschedule from 'content/features/schedulerSteps/SelectReschedule';
import VehicleOwnerStep from 'content/features/schedulerSteps/VehicleOwnerStep';
import { setResetSteps } from 'content/app.actions';

function Rescheduler({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeStep = useSelector((state) => state.app.activeStep);
  const view = useSelector((state) => state.app.view);

  useEffect(() => {
    if (view === '') {
      navigate('/reschedule');
    }
  }, [view]);

  const views = [<SelectReschedule />, <DateForm />, <ResumeView />];

  const getStepContent2 = () => {
    const component = views[activeStep];

    return component || null;
  };

  useEffect(
    () => () => {
      dispatch(setResetSteps());
    },
    [dispatch],
  );

  return (
    <StyledContainer title={title}>
      <Steps stepsNumber={3}>
        <Grid style={{ marginTop: '30px' }}>{getStepContent2()}</Grid>
      </Steps>
    </StyledContainer>
  );
}

export default Rescheduler;
