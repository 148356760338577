const navbarStyles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: (theme) => theme.spacing(2),
    color: '#fff',
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: (theme) => theme.spacing(2),
    fontFamily: 'Roboto',
  },
};

export default navbarStyles;
