/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

/* Material UI */
import { createTheme, Fab, Slide, ThemeProvider } from '@mui/material';

/* Socket IO */
import socketIOClient from 'socket.io-client';

/* React Redux  */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Toastify */
import { ToastContainer } from 'react-toastify';

/* Project */
import './App.scss';
import defaultTheme from 'utils/theme';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import environments from 'config/environments';
import { Phone } from '@mui/icons-material';
import Router from './handlers/Router';

import { getStateFromApi } from './app.actions';
import {
  clearTechnician,
  getAvailabilitiesSuccess,
  getSchedulerResponse,
  getTechniciansAndAvailabilitiesSuccess,
  getUserDataSuccess,
  getUserVehicleWithConectaApiSuccess,
  schedulerInternalError,
  toggleSchedulerError,
} from './features/schedulerSteps/schedulerSteps.actions';
import {
  getAppointmentsSuccess,
  toggleNoRecordsError,
} from './features/welcomeScreens/welcomeScreens.actions';
import {
  cancelAppointmentSuccess,
  toggleErrorDialog,
} from './features/annulmentSteps/annulmentSteps.actions';

function App({ route }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect route for test environment
    if (route) navigate(route);
  }, [route]);

  const user = useSelector((state) => state.app.user);
  const loading = useSelector((state) => state.app.loading);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: environments.GOOGLE_CLIENT_ID,
      });
    }

    dispatch(getStateFromApi());
  }, []);

  const webSocketInstance = useCallback(() => socketIOClient(environments.WS_ENDPOINT), []);

  const socket = useMemo(() => webSocketInstance(), [user]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        localStorage.setItem('socketId', socket.id);
      });

      socket.on('[Notification]', (data) => {
        defaultSuccessToast({ message: data.message });
      });

      socket.on('[QUEUE_RESPONSE]', () => {
        // console.log({ data });
        defaultSuccessToast({ message: 'A new action is register' });
      });

      socket.on('[VEHICLE_QUEUE_RESPONSE]', (data) => {
        if (!data.error) {
          dispatch(getUserVehicleWithConectaApiSuccess(data));
        } else {
          const errorMsg = data?.errorMsg;
          dispatch(toggleSchedulerError({ errorMsg }));
        }
      });

      socket.on('[USER_BY_RUT_QUEUE_RESPONSE]', (data) => {
        // console.log('data', data);
        if (!data.error) {
          dispatch(getUserDataSuccess(data));
        } else {
          const errorMsg = data?.errorMsg;
          dispatch(toggleSchedulerError({ errorMsg }));
        }
      });

      socket.on('[AVAILAVILITIES_AND_TECHNICIANS]', (data) => {
        if (!data.error) {
          dispatch(getTechniciansAndAvailabilitiesSuccess(data));
        } else {
          const errorMsg = data?.errorMsg;
          dispatch(toggleSchedulerError({ errorMsg }));
        }
      });

      socket.on('[TECHNICIAN_AVAILABILITIES]', (data) => {
        if (!data.error) {
          dispatch(getAvailabilitiesSuccess(data));
        } else {
          const errorMsg = data?.errorMsg;
          dispatch(clearTechnician());
          dispatch(toggleSchedulerError({ errorMsg }));
        }
      });

      socket.on('[CREATE_SCHEDULE]', (data) => {
        // console.log('dataaaaa', data);
        if (!data.error) {
          dispatch(getSchedulerResponse(data));
        } else {
          dispatch(
            schedulerInternalError({
              error: data.error,
              errorType: data.technicianError ? 'technician-error' : 'server-error',
            }),
          );
        }

        // console.log('data', data);

        // if (!data.error) {
        //   dispatch(getAvailabilitiesSuccess(data));
        // } else {
        //   const errorMsg = data?.errorMsg;
        //   dispatch(toggleSchedulerError({ errorMsg }));
        // }
      });

      socket.on('[RESCHEDULE_SERVICE]', (data) => {
        // console.log('[RESCHEDULE_SERVICE]', data);
        if (!data.error) {
          dispatch(getSchedulerResponse(data));
        } else {
          dispatch(
            schedulerInternalError({
              error: data.error,
              errorType: 'server-error',
            }),
          );
        }
      });

      socket.on('[RETRIEVE_SCHEDULED_SERVICES]', (data) => {
        // console.log('[RETRIEVE_SCHEDULED_SERVICES]', data);
        if (!data.error) {
          dispatch(getAppointmentsSuccess(data));
          navigate(`/${data.view}/steps`);
        } else {
          dispatch(toggleNoRecordsError());
        }
      });

      socket.on('[CANCEL_SERVICE]', (data) => {
        // console.log('[CANCEL_SERVICE]', data);
        if (!data.error) {
          dispatch(cancelAppointmentSuccess(data));
          navigate(`/annulment/success`);
        } else {
          dispatch(toggleErrorDialog());
        }
      });

      return () => {
        socket.disconnect();
      };
    }

    return () => {};
  }, [socket]);
  const theme = createTheme(defaultTheme);

  const [isHovered, setIsHovered] = useState(false);
  const salfaClickToCallLink = process.env.REACT_APP_LINK_CLICK_TO_CALL;
  const handleMouseEnter = () => {
    setIsHovered(true);
    setTimeout(() => {
      setIsHovered(false);
    }, 2500);
  };

  const fabStyle = {
    position: 'fixed',
    bottom: '25vh',
    right: '0vh',
    marginRight: '1vh',
  };

  return (
    <ThemeProvider theme={theme}>
      <Router user={user} loading={loading} />
      <ToastContainer />
      {isHovered ? (
        <Slide direction="left" in={isHovered}>
          <Fab
            onClick={() => window.open(salfaClickToCallLink, '_blank', 'noopener noreferrer')}
            sx={fabStyle}
            size="large"
            color="primary"
            variant="extended">
            ¿Necesitas ayuda?
          </Fab>
        </Slide>
      ) : (
        <Fab
          onMouseEnter={handleMouseEnter}
          sx={fabStyle}
          size="medium"
          color="primary"
          variant="circular">
          <Phone />
        </Fab>
      )}
    </ThemeProvider>
  );
}

export default App;
