import React from 'react';

/* Material */
import { Grid, Typography } from '@mui/material';

/* Project */
import SchedulerStepsStyles from '../../SchedulerSteps.styles';
import BrandsCards from '../BrandCard';

function SelectBrandSection() {
  return (
    <Grid id="brand-section" style={{ margin: '20px 0px 20px 0px' }}>
      <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.title}>
        Selección marca
      </Typography>
      <BrandsCards />
    </Grid>
  );
}

export default SelectBrandSection;
