/* Redux Saga */
import { spawn, takeLatest, put } from 'redux-saga/effects';

/* Project */
import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import { createUser, createUserSuccess, createUserFail } from './register.actions';

function* registerUser() {
  yield takeLatest(createUser, function* createRegularUser(action) {
    const { email, name, password, type, navigate } = action.payload;
    const body = JSON.stringify({ email, name, password, type });

    const response = yield apiRequest('api/v1/auth/register', { method: 'post', body });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(createUserFail());
    } else {
      defaultSuccessToast({ message: 'Usuario creado exitosamente.' });
      navigate('/login');
      yield put(createUserSuccess());
    }
  });
}

export default function* RegisterSaga() {
  yield spawn(registerUser);
}
