/* eslint-disable complexity */
import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import {
  confirmBooking,
  confirmBookingFailed,
  confirmBookingSuccess,
  getData,
  getDataFailed,
  getDataSuccess,
  setConfirmData,
} from './confirm.actions';

function* getBookingData() {
  yield takeLatest(getData, function* getBookingDatos(action) {
    const payloadData = { ...action.payload };
    const body = JSON.stringify(payloadData);
    const response = yield apiRequest('api/v1/confirm/getData', {
      method: 'post',
      body,
    });
    if (!response.error) {
      if (response.agendamientoEstadoName === 'Confirmado') {
        yield put(confirmBookingSuccess());
        yield put(setConfirmData(response));
      } else {
        yield put(getDataSuccess(response));
      }
    } else {
      yield put(getDataFailed());
    }
  });
}

function* confirmBookingSchedule() {
  yield takeLatest(confirmBooking, function* confirm(action) {
    const payloadData = { ...action.payload };
    const body = JSON.stringify(payloadData);
    const response = yield apiRequest('api/v1/confirm/booking', {
      method: 'post',
      body,
    });
    if (response?.success) {
      yield put(confirmBookingSuccess());
    } else {
      yield put(confirmBookingFailed());
    }
  });
}

export default function* ConfirmSaga() {
  yield spawn(getBookingData);
  yield spawn(confirmBookingSchedule);
}
