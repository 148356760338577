import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

function ValidationStyledContainer(props) {
  const { children, title = 'AUTO AGENDAMIENTO SERVICIO LIVIANO' } = props;
  return (
    <Grid container justifyContent="center" padding={4}>
      <Grid item xs={12} sm={4} padding={2} sx={{ padding: { xs: '0', sm: '2px' } }}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
        <Paper square={false} sx={{ borderRadius: 6 }}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ValidationStyledContainer;
