/* eslint-disable complexity */
import React from 'react';

/* Material UI */
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

function CalendarSkeleton(props) {
  const { isMobile } = props;

  const styles = isMobile
    ? {
        grid: {
          minHeight: '50vh',
        },
        firstSkeletonWidth: '30%',
        secondSkeletonWidth: '7%',
        thirdSkeletonHeight: 250,
      }
    : {
        grid: {
          minHeight: '650px',
        },
        firstSkeletonWidth: '15%',
        secondSkeletonWidth: '13%',
        thirdSkeletonHeight: 600,
      };

  return (
    <Grid style={styles.grid}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: 40,
          marginBottom: '8px',
        }}>
        <Skeleton variant="rounded" width={styles.firstSkeletonWidth} height={35} />
        <Skeleton variant="rounded" width="40%" height={25} />
        <Skeleton variant="rounded" width={styles.secondSkeletonWidth} height={35} />
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={styles.thirdSkeletonHeight} />
    </Grid>
  );
}

export default CalendarSkeleton;
