import React from 'react';
import PropTypes from 'prop-types';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Project
// import Logo from '/assets/images/TcitLogo_123x50.png';
import { useDispatch } from 'react-redux';
import { resetStepsState } from 'content/features/schedulerSteps/schedulerSteps.actions';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import navbarStyles from './navbar.styles';

const SalfaLogo = `${window.location.origin}/images/salfa-logo.png`;

function NavBar(props) {
  const { setOpenDrawer } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectHome = () => {
    dispatch(resetStepsState());
    dispatch(resetWelcomeScreenControls());
    dispatch(resetAnnulmentState());
    navigate('/');
  };

  return (
    <AppBar position="static" color="inherit" style={{ background: '#19255B' }}>
      <Toolbar>
        <IconButton
          data-testid="toolbar-menu-button"
          onClick={() => setOpenDrawer(true)}
          edge="start"
          sx={navbarStyles.menuButton}
          color="inherit"
          aria-label="menu">
          <MenuIcon />
        </IconButton>
        <IconButton onClick={redirectHome}>
          <img style={{ height: '30px' }} src={SalfaLogo} alt="" />
        </IconButton>
        <Typography variant="h6" sx={navbarStyles.title} color="primary" />
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
