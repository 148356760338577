export const viewTypes = {
  schedule: { title: 'agendamiento', grammar: 'el', successRoute: '/schedule/success' },
  annulment: { title: 'anulación', grammar: 'la', successRoute: '/annulment/success' },
  reschedule: { title: 'reagendamiento', grammar: 'el', successRoute: '/reschedule/success' },
};

export const voucherTitles = {
  schedule: { title: 'agendamiento exitoso' },
  annulment: { title: 'agendamiento cancelado' },
  reschedule: { title: 'reagendamiento exitoso' },
};
