import React from 'react';

import { Typography, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import useForm from 'hooks/useForm';
import { RecoveryActions } from 'content/features/authentication';
import { validatePassword } from 'utils/functions';

function UpdatePasswordForm(props) {
  const { styles, onChangeText, controls, password, verifyPassword, loading, navigate } = props;

  const dispatch = useDispatch();

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => validatePassword(value).check,
          message: validatePassword('').message,
        },
      ],
      verifyPassword: [
        {
          check: (value) => !!value,
          message: 'La verificación de contraseña es requerida',
        },
        {
          check: (value) => value === password,
          message: 'Las contraseñas deben coincidir',
        },
      ],
    },
  });

  const onClickUpdatePassword = (event) => {
    event.preventDefault();
    onSubmit(() =>
      dispatch(
        RecoveryActions.updatePassword({
          token: controls.token,
          email: controls.email,
          password: controls.password,
          navigate,
        }),
      ),
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };

  return (
    <form onSubmit={onClickUpdatePassword}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Typography variant="body1" component="div">
            Por favor, ingrese su nueva contraseña.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
            sx={styles.field}
            label="Nueva contraseña"
            key="password"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            style={{ paddingBottom: '8px', marginBottom: '5%' }}
            fullWidth
            error={!!errors?.password[0]}
            helperText={errors?.password[0]}
          />
          <TextField
            sx={styles.field}
            label="Repita su nueva contraseña"
            key="verifyPassword"
            id="verifyPassword"
            name="verifyPassword"
            type="password"
            value={verifyPassword}
            onChange={handleChange}
            style={{ paddingBottom: '8px', marginBottom: '5%' }}
            fullWidth
            error={!!errors?.verifyPassword[0]}
            helperText={errors?.verifyPassword[0]}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ ...styles.button, width: 'auto' }}>
            Actualizar contraseña
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default UpdatePasswordForm;
