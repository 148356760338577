import React, { useState } from 'react';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Material UI */
import { Grid } from '@mui/material';
import { LoginActions } from 'content/features/authentication';
import { Footer, NavBar, TemporaryDrawer } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const signedByGoogle = useSelector((state) => state.app.signedByGoogle);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    dispatch(LoginActions.logout({ user, signedByGoogle }));
  };

  return (
    <Grid
      justifyContent="space-between"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}>
      <NavBar setOpenDrawer={setOpenDrawer} user={user} handleLogout={handleLogout} />
      <Grid
        container
        justifyContent="center"
        sx={{
          paddingTop: '16px',
          paddingBottom: '16px',
          alignSelf: 'flex-start',
          background: 'rgba(172,188,227,0.15)',
        }}>
        <Grid container item md={11} sm={12}>
          {element || children}
        </Grid>
      </Grid>
      <Grid sx={{ height: '100%', background: 'rgba(172,188,227,0.15)' }} />
      <Footer />
      <TemporaryDrawer openByDefault={openDrawer} setOpenDrawer={setOpenDrawer} />
    </Grid>
  );
}

export default Layout;

/* <>
      <NavBar setOpenDrawer={setOpenDrawer} user={user} handleLogout={handleLogout} />
      <div className="content-container">
        <Grid container justifyContent="center" sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
          <Grid container item md={11} sm={12}>
            {element || children}
          </Grid>
        </Grid>
        <Footer />
      </div>
      <TemporaryDrawer openByDefault={openDrawer} setOpenDrawer={setOpenDrawer} />
    </> */
