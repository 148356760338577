import React, { useEffect } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { Snackbar, Alert } from '@mui/material';
import Grid from '@mui/material/Grid';

/* Project */
import Steps from 'content/features/steps/Steps';
import StyledContainer from 'content/containers/StyledContainer';
import VehicleForm from 'content/features/schedulerSteps/VehicleForm';
import ServiceForm from 'content/features/schedulerSteps/ServiceForm';
import LocationStep from 'content/features/schedulerSteps/LocationStep';
import VehicleOwnerStep from 'content/features/schedulerSteps/VehicleOwnerStep';
import DateForm from 'content/features/schedulerSteps/DateForm';
import ResumeView from 'content/features/schedulerSteps/ResumeView';
import { setResetSteps } from 'content/app.actions';
import { clearSchedulerError } from 'content/features/schedulerSteps/schedulerSteps.actions';

function Scheduler() {
  const activeStep = useSelector((state) => state.app.activeStep);
  const view = useSelector((state) => state.app.view);
  const error = useSelector((state) => state.schedulerSteps.error);
  const errorMsg = useSelector((state) => state.schedulerSteps.errorMsg);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (view === '') {
      navigate('/');
    }
  }, [view]);

  const views = [
    <VehicleForm />,
    <ServiceForm />,
    <LocationStep />,
    <VehicleOwnerStep />,
    <DateForm />,
    <ResumeView />,
  ];

  const getStepContent2 = () => {
    const component = views[activeStep];

    return component || null;
  };

  useEffect(
    () => () => {
      dispatch(setResetSteps());
    },
    [dispatch],
  );

  const emptyErrors = () => {
    dispatch(clearSchedulerError());
  };

  return (
    <StyledContainer title="AGENDAMIENTO TALLER">
      <Steps stepsNumber={6}>
        <Grid style={{ marginTop: 30, paddingLeft: 40, paddingRight: 40 }}>
          {getStepContent2()}
        </Grid>
      </Steps>
      <Snackbar
        open={error}
        onClose={emptyErrors}
        autoHideDuration={9000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default Scheduler;
