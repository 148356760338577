import React from 'react';

/* Material UI */
import { Grid, Typography, Hidden } from '@mui/material';

/* Project */
import FindVehicleSection from './components/vehicle-form/FindVehicleSection';
import SelectBrandSection from './components/vehicle-form/SelectBrandSection';
import VehicleDataSection from './components/vehicle-form/VehicleDataSection';
import CustomStepsActionButtons from './components/vehicle-form/CustomStepsActionButtons';
import SchedulerStepsStyles from './SchedulerSteps.styles';

function VehicleForm() {
  return (
    <>
      <Grid id="patent-section">
        <Hidden smUp>
          <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.titleMobile}>
            Datos vehículo
          </Typography>
        </Hidden>
        <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.title}>
          Ingresar patente
        </Typography>
        <FindVehicleSection />
      </Grid>
      <SelectBrandSection />
      <VehicleDataSection />
      <CustomStepsActionButtons />
    </>
  );
}

export default VehicleForm;
