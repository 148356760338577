/* eslint-disable */
import * as React from 'react';

/* React Redux */
import { useSelector } from 'react-redux';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useMediaQuery, useTheme } from '@mui/material';

/* Project */
import StepsMobile from './StepsMobile';

function Steps(props) {
  const { stepsNumber, children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const steps = [...Array(stepsNumber)].map((_, index) => index + 1);
  const activeStep = useSelector((state) => state.app.activeStep);

  return (
    <Box sx={{ width: '100%', padding: 0 }}>
      <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 0 }}>
        <Grid item xs={12} sm={12} md={7} lg={6}>
          {isMobile ? (
            <StepsMobile steps={steps} activeStep={activeStep} />
          ) : (
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel key={label} {...labelProps}>{}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </Grid>
      </Grid>
      <div>
        <Grid>{children}</Grid>
      </div>
    </Box>
  );
}

/* Steps.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
}; */

export default Steps;
