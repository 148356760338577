import { CircularProgress, Dialog, Grid, Typography } from '@mui/material';
import React from 'react';
import LoaderDialogStyles from './LoaderDialog.styles';

function LoaderDialog(props) {
  const { open, title } = props;
  return (
    <Dialog open={open}>
      <Grid sx={LoaderDialogStyles.dialogGrid}>
        <CircularProgress color="primary" size={40} sx={{ marginRight: '20px' }} />
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Grid>
    </Dialog>
  );
}

export default LoaderDialog;
