import React, { useEffect } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

/* MomentJS */
import moment from 'moment';

/* React Big Calendar */
import { momentLocalizer } from 'react-big-calendar';

/* Project */
import SchedulerStepsStyles from 'content/features/schedulerSteps/SchedulerSteps.styles';
import {
  clearDate,
  getAvailabilities,
  setMonth,
} from 'content/features/schedulerSteps/schedulerSteps.actions';

function CustomToolbar(props) {
  const { label, onNavigate, date } = props;
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);
  const selectedDate = useSelector((state) => state.schedulerSteps.controls.selectedDate);
  const welcomeScreensSelectedService = useSelector(
    (state) => state.welcomeScreen.controls.selectedService,
  );
  const selectedTechnician = useSelector(
    (state) => state.schedulerSteps.controls.selectedTechnician,
  );
  const selectedServiceCode = useSelector(
    (state) => state.schedulerSteps.controls.selectedServiceCode,
  );
  const month = useSelector((state) => state.schedulerSteps.controls.month);
  const currentDate = moment().startOf('day').toDate();
  const calendarMonth = moment(date).format('YYYY-MM');
  const currentMonth = moment(currentDate).format('YYYY-MM');
  const nextYearLimitDate = moment(currentDate).add(1, 'year').format('YYYY-MM');
  const calendarYearMonth = moment(date).format('YYYY-MM');
  const disabledBackButton = moment(calendarMonth).isSameOrBefore(currentMonth);
  const disabledNextButton = calendarYearMonth === nextYearLimitDate;

  const setCalendarMonth = (payload) => {
    dispatch(setMonth({ month: payload }));
  };

  const retrieveAvailabilities = (calendarNewMonth) => {
    const startDate = moment(calendarNewMonth).startOf('month').startOf('day').utc().format();
    const endDate = moment(calendarNewMonth).endOf('month').endOf('day').utc().format();

    const selectedService =
      welcomeScreensSelectedService !== '' ? welcomeScreensSelectedService : selectedServiceCode;

    if (selectedTechnician !== '') {
      dispatch(
        getAvailabilities({
          calendarLoading: true,
          selectedService,
          startDate,
          endDate,
          technicianBusinessPartnerId: selectedTechnician.businessPartnerId,
        }),
      );
    }
  };

  const goNext = () => {
    const nextMonth = moment(month).add(1, 'M');
    setCalendarMonth(nextMonth);
    onNavigate('next', nextMonth);
    retrieveAvailabilities(nextMonth);
  };

  const goBack = () => {
    const prevMonth = moment(month).subtract(1, 'M');
    setCalendarMonth(prevMonth);
    onNavigate('prev', prevMonth);
    retrieveAvailabilities(prevMonth);
  };

  const goToday = () => {
    const today = moment();
    setCalendarMonth(today);
    onNavigate('today', today);
    retrieveAvailabilities(today);
  };

  const goBackToolbar = () => {
    dispatch(clearDate());
    goBack();
  };

  const goNextToolbar = () => {
    dispatch(clearDate());
    goNext();
  };

  const goBackOrNextValidation = (selectedDate) => {
    const formatedMonth = moment(month).format('YYYY-MM');
    const formatedDate = moment(selectedDate).format('YYYY-MM');

    if (moment(formatedDate).isAfter(formatedMonth)) {
      goNext();
    } else if (moment(formatedDate).isBefore(formatedMonth)) {
      goBack();
    } else {
    }
  };

  useEffect(() => {
    if (selectedDate) {
      goBackOrNextValidation(selectedDate);
    }
  }, [selectedDate]);

  return (
    <Grid sx={SchedulerStepsStyles.calendarToolbar}>
      <ButtonGroup variant="outlined">
        <Button
          disabled={disabledBackButton}
          onClick={() => goBackToolbar()}
          sx={{ padding: '5px 8px' }}>
          <Hidden smDown>Atrás</Hidden>
          <Hidden smUp>
            <KeyboardArrowLeftIcon />
          </Hidden>
        </Button>
        <Button
          onClick={() => goNextToolbar()}
          sx={{ padding: '5px 8px' }}
          disabled={disabledNextButton}>
          <Hidden smDown>Siguiente</Hidden>
          <Hidden smUp>
            <KeyboardArrowRightIcon />
          </Hidden>
        </Button>
      </ButtonGroup>

      <Typography variant="h6" sx={SchedulerStepsStyles.calendarToolbarLabel}>
        {label}
      </Typography>
      <Button variant="outlined" onClick={() => goToday()}>
        Hoy
      </Button>
    </Grid>
  );
}

export default CustomToolbar;
