import { createReducer } from '@reduxjs/toolkit';

import {
  disableErrors,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
  setControl,
  setControls,
  resetControls,
  setParams,
  resetParams,
  resetState,
  sendRecoverPasswordEmail,
  sendRecoverPasswordEmailSuccess,
  sendRecoverPasswordEmailFail,
} from './recovery.actions';

const initialState = {
  controls: {
    email: '',
    password: '',
    verifyPassword: '',
  },
  params: {
    token: '',
  },
  successChangePassword: false,
  loading: false,
  message: '',
};

const RecoveryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(disableErrors, (state) => {
      state.error = false;
    })
    .addCase(updatePassword, (state) => {
      state.loading = true;
    })
    .addCase(updatePasswordSuccess, (state) => {
      state.loading = false;
    })
    .addCase(updatePasswordFail, (state) => {
      state.loading = false;
      state.successChangePassword = false;
    })
    .addCase(setControl, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(setControls, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(resetControls, (state) => {
      state.controls = { ...initialState.controls };
    })
    .addCase(setParams, (state, { payload }) => {
      state.params = { ...state.params, ...payload };
    })
    .addCase(resetParams, (state) => {
      state.params = { ...initialState.params };
    })
    .addCase(resetState, () => ({ ...initialState }))
    .addCase(sendRecoverPasswordEmail, (state) => {
      state.loading = true;
    })
    .addCase(sendRecoverPasswordEmailSuccess, () => ({ ...initialState }))
    .addCase(sendRecoverPasswordEmailFail, (state) => {
      state.loading = false;
    });
});

export default RecoveryReducer;
