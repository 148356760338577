const LoaderDialogStyles = {
  dialogGrid: {
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default LoaderDialogStyles;
