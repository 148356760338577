import React from 'react';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { Alert, Button } from '@mui/material';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function InfoAlert(props) {
  const { controls } = props;
  const navigate = useNavigate();

  const redirectAnnulment = () => {
    if (controls.selectedReschedule.length > 0) {
      const encodeUriArray = encodeURIComponent(JSON.stringify(controls.selectedReschedule));
      navigate(`/annulment?id=${[encodeUriArray]}`);
    } else {
      navigate('/annulment');
    }
  };

  return (
    <Alert severity="info">
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li>Solo puede seleccionar un agendamiento a la vez.</li>
        <li>No se puede anular un agendamiento con menos de 24 horas de anticipación. </li>
        <li>
          Si los datos del vehículo están erróneos, anule ese agendamiento{' '}
          <Button onClick={redirectAnnulment} sx={SchedulerStepsStyles.buttonLink}>
            aquí
          </Button>{' '}
          y cree otro agendamiento nuevamente.
        </li>
        <li>
          Si desea cambiar de región o taller, debe anular el agendamiento{' '}
          <Button onClick={redirectAnnulment} sx={SchedulerStepsStyles.buttonLink}>
            aquí
          </Button>{' '}
          y crear otro agendamiento nuevamente.
        </li>
      </ul>
    </Alert>
  );
}

export default InfoAlert;
