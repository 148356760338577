/* React Redux */
import { createAction } from '@reduxjs/toolkit';

/* Project */
import ConfirmTypes from './confirm.types';

export const getData = createAction(ConfirmTypes.GET_DATA, (payload) => ({
  payload,
}));

export const getDataSuccess = createAction(ConfirmTypes.GET_DATA_SUCCESS, (payload) => ({
  payload,
}));

export const getDataFailed = createAction(ConfirmTypes.GET_DATA_FAILED, (payload) => ({
  payload,
}));

export const confirmBooking = createAction(ConfirmTypes.CONFIRM_BOOKING, (payload) => ({
  payload,
}));

export const confirmBookingSuccess = createAction(
  ConfirmTypes.CONFIRM_BOOKING_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const confirmBookingFailed = createAction(
  ConfirmTypes.CONFIRM_BOOKING_FAILED,
  (payload) => ({
    payload,
  }),
);

export const resetConfirmState = createAction(ConfirmTypes.RESET_CONFIRM_STATE, (payload) => ({
  payload,
}));

export const setConfirmData = createAction(ConfirmTypes.SET_CONFIRM_DATA, (payload) => ({
  payload,
}));

const ConfirmActions = {
  getData,
  getDataSuccess,
  getDataFailed,
  confirmBooking,
  confirmBookingSuccess,
  confirmBookingFailed,
  resetConfirmState,
  setConfirmData,
};

export default ConfirmActions;
