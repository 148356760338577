import * as React from 'react';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function ReScheduleMobileDialog(props) {
  const { open, handleClose, appointment } = props;
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogContent>
          <Grid container>
            <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.title}>
              ID Ticket #{appointment?.idTicket}
            </Typography>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Patente
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.patent}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Marca
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.brand}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Modelo
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.model}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Versión
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.version}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Tipo servicio
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.serviceType}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Fecha
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.serviceDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sx={SchedulerStepsStyles.infoLabelContainer}>
              <Grid xs={5}>
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Sucursal
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2" color="primary">
                  {appointment?.workshop}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReScheduleMobileDialog;
