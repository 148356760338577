import React, { useEffect } from 'react';

/* Material UI */
import { Grid } from '@mui/material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* React Router */

/* Project */
import LoaderDialog from '../loaders/LoaderDialog';
import ConfirmCard from './components/ConfirmCard';
import { getData } from './confirm.actions';

function ConfirmBooking(props) {
  const { data, ticketID, loading } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData({ ticketID }));
  }, []);

  return (
    <Grid>
      <ConfirmCard data={data} ticketID={ticketID} />
      <LoaderDialog open={loading} title="Confirmar Agendamiento" />
    </Grid>
  );
}

export default ConfirmBooking;
