/* React Redux */
import { createAction } from '@reduxjs/toolkit';

/* Project */
import AnnulmentStepsTypes from './annulmentSteps.types';

export const controlsChanged = createAction(
  AnnulmentStepsTypes.CONTROLS_CHANGED,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const selectAnnulment = createAction(AnnulmentStepsTypes.SELECT_ANNULMENT, (payload) => ({
  payload,
}));

export const openAppointmentInfo = createAction(
  AnnulmentStepsTypes.OPEN_APPOINTMENT_INFO,
  (payload) => ({
    payload,
  }),
);

export const closeAppointmentInfo = createAction(AnnulmentStepsTypes.CLOSE_APPOINTMENT_INFO);

export const cancelAppointment = createAction(
  AnnulmentStepsTypes.CANCEL_APPOINTMENT,
  (payload) => ({
    payload,
  }),
);

export const cancelAppointmentSuccess = createAction(
  AnnulmentStepsTypes.CANCEL_APPOINTMENT_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const resetAnnulmentState = createAction(AnnulmentStepsTypes.RESET_STATE);

export const getAnnulmentDocument = createAction(AnnulmentStepsTypes.GET_VOUCHER_DOCUMENT, (payload) => ({
  payload,
}));

export const getAnnulmentDocumentSuccess = createAction(
  AnnulmentStepsTypes.GET_VOUCHER_DOCUMENT_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const toggleErrorDialog = createAction(AnnulmentStepsTypes.TOGGLE_ERROR_DIALOG);
export const clearErrorDialog = createAction(AnnulmentStepsTypes.CLEAR_ERROR_DIALOG);

const AnnulmentStepsActions = {
  controlsChanged,
  selectAnnulment,
  openAppointmentInfo,
  closeAppointmentInfo,
  cancelAppointment,
  cancelAppointmentSuccess,
  resetAnnulmentState,
  getAnnulmentDocument,
  toggleErrorDialog,
  clearErrorDialog,
};

export default AnnulmentStepsActions;
