import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Grid } from '@mui/material';

/* Project */
import environments from 'config/environments';

function GoogleButton(props) {
  const { sx, size, theme, handleCallbackResponse } = props;

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: environments.GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });

      window.google.accounts.id.renderButton(document.getElementById('g_id_signin'), {
        theme,
        size,
      });
    }
  }, []);

  return <Grid id="g_id_signin" sx={{ ...sx }} />;
}

GoogleButton.propTypes = {
  handleCallbackResponse: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  size: PropTypes.string,
  theme: PropTypes.string,
};

GoogleButton.defaultProps = {
  sx: {},
  size: 'small',
  theme: 'outline',
};

export default GoogleButton;
