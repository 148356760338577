import React, { useMemo, useState } from 'react';

/* Material UI */
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import { UserTasksActions } from 'content/features/tasks';
import {
  controlsSelector,
  creationLoadingSelector,
} from 'content/features/tasks/userTasks.selector';
import { changeControls } from 'content/features/tasks/userTasks.actions';

function UserTaskForm() {
  const dispatch = useDispatch();

  const controls = useSelector(controlsSelector);
  const loading = useSelector(creationLoadingSelector);

  const { description, name } = controls;

  const [error, setError] = useState(false);

  const validTaskPayload = useMemo(() => {
    const validations = {
      name: name.trim().length > 3,
      description: description.trim().length > 3,
    };
    return Object.values(validations).every((value) => value);
  }, [description, name]);

  const onChangeControls = (event) => {
    const {
      target: { id, value },
    } = event;

    dispatch(changeControls({ name: id, value }));
  };

  const addTask = () => {
    if (!validTaskPayload) {
      return setError(true);
    }

    dispatch(UserTasksActions.createTask({ name, description }));

    return true;
  };

  const onAddTask = (event) => {
    event.preventDefault();
    addTask();
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '-webkit-fill-available' },
      }}
      noValidate
      onSubmit={onAddTask}
      autoComplete="off">
      <TextField
        required
        id="description"
        label="Añadir tarea: "
        value={description}
        onChange={onChangeControls}
        error={error}
        placeholder="Descripción de tarea"
      />
      <LoadingButton loading={loading} variant="outlined" onClick={() => addTask()}>
        Añadir
      </LoadingButton>
    </Box>
  );
}

UserTaskForm.propTypes = {};

export default UserTaskForm;
