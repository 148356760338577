import React from 'react';

/* Material UI */
import { Grid, Typography, Hidden } from '@mui/material';

/* Project */
import textImageValidationSelectedService from 'content/shared/textImageValidationSelectedService';
import SchedulerStepsStyles from '../SchedulerSteps.styles';

const maintenance = `${window.location.origin}/images/taller2.jpg`;
const diagnosis = `${window.location.origin}/images/diagnosis.jpg`;

function ServiceImageSection(props) {
  const {
    serviceType,
    lg = 4,
    md = 5,
    xs = 12,
    sm = 12,
    item = false,
    margin = 0,
    onClick,
    style,
    sx,
  } = props;
  const { textValidation, textValidationImage } = textImageValidationSelectedService[serviceType];

  return (
    <Hidden mdDown>
      <Grid
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        item={item}
        margin={margin}
        onClick={onClick}
        id="image-section"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(108,108,108,0) 86%, rgba(249,249,249,0) 100%),url(${
            serviceType === 'maintenance' ? maintenance : diagnosis
          })`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '5px',
          padding: '20px',
          ...style,
        }}
        sx={{ ...SchedulerStepsStyles.imageBackground, ...sx }}>
        <Typography sx={{ color: '#fff' }} variant="h6" gutterBottom>
          {textValidation}
        </Typography>
        <Typography sx={{ color: '#fff', whiteSpace: 'break-spaces' }} variant="body2" gutterBottom>
          {textValidationImage}
        </Typography>
      </Grid>
    </Hidden>
  );
}

export default ServiceImageSection;
