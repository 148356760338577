import React from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Box, Button, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Project */
import { setPreviousStep } from 'content/app.actions';
import { useNavigate } from 'react-router-dom';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import { clearSelectedDate, resetStepsState } from '../schedulerSteps.actions';

function StepsActionButtons(props) {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.app.activeStep);
  const view = useSelector((state) => state.app.view);
  const { disabled, handleNext, withLoader } = props;

  const loading = useSelector((state) => state.schedulerSteps.loading);
  const calendarLoading = useSelector((state) => state.schedulerSteps.calendarLoading);

  const maintenanceLoader = loading || calendarLoading;
  const navigate = useNavigate();

  const handleBack = () => {
    if (activeStep === 4) {
      dispatch(clearSelectedDate());
    }
    dispatch(setPreviousStep());
  };

  const handleGoHome = () => {
    dispatch(resetStepsState());
    dispatch(resetWelcomeScreenControls());
    dispatch(resetAnnulmentState());
    if (view === 'reschedule') navigate('/reschedule');
    if (view === 'schedule') navigate('/');
  };

  const nextButton = (
    <Button variant="contained" disabled={disabled || maintenanceLoader} onClick={handleNext}>
      Siguiente
    </Button>
  );

  const loadingButton = (
    <LoadingButton
      loading={loading}
      variant="contained"
      onClick={handleNext}
      disabled={maintenanceLoader}>
      {activeStep === 5 ? 'Agendar' : 'Siguiente'}
    </LoadingButton>
  );

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const actionButton = withLoader ? loadingButton : nextButton;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: smUp ? 'row-reverse' : 'column',
        justifyContent: 'space-between',
        pt: 5,
      }}>
      {actionButton}
      <Button
        color="inherit"
        disabled={maintenanceLoader}
        onClick={activeStep === 0 ? handleGoHome : handleBack}
        sx={{ mr: 1, mt: smUp ? 0 : 2 }}>
        Atrás
      </Button>
    </Box>
  );
}

export default StepsActionButtons;
