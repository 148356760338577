import React from 'react';

/* Material UI */
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  FormHelperText,
  CircularProgress,
  Box,
} from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router DOM */
import { useNavigate } from 'react-router-dom';

/* Project */
import { resetStepsState } from 'content/features/schedulerSteps/schedulerSteps.actions';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import AnnulmentStepsStyles from '../AnnulmentSteps.styles';

function CancellationReason(props) {
  const { onSubmit, controls, errors, onChange, loading } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { objects } = useSelector((state) => state.app);
  const smUp = useMediaQuery(() => theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const disableReschedule = controls.selectedAnnulments.length > 1;

  const redirectReschedule = () => {
    if (controls.selectedAnnulments.length === 1) {
      navigate(`/reschedule/steps/update-scheduling?id=${controls.selectedAnnulments[0]}`);
    } else {
      navigate(`/reschedule`);
    }
  };

  const handleGoHome = () => {
    dispatch(resetStepsState());
    dispatch(resetWelcomeScreenControls());
    dispatch(resetAnnulmentState());
    navigate('/annulment');
  };

  return (
    <>
      <Grid sx={{ marginBottom: '20px' }}>
        <Typography color="primary" variant="h6" sx={AnnulmentStepsStyles.title}>
          Motivo de anulación
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4} sx={{ marginBottom: '20px' }}>
        <FormControl fullWidth size="small" sx={AnnulmentStepsStyles.field}>
          <InputLabel id="demo-simple-select-label">Seleccionar motivo de anulación</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="annulmentMotive"
            name="annulmentMotive"
            key="annulmentMotive"
            label="Seleccionar motivo de anulación"
            value={controls.annulmentMotive}
            onChange={onChange}
            error={!!errors.annulmentMotive[0]}
            fullWidth>
            {Object.values(objects?.annulmentMotives).map((annulmentMotive) => (
              <MenuItem key={annulmentMotive?.id} value={annulmentMotive?.id}>
                {annulmentMotive?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!errors.annulmentMotive}>
            {errors.annulmentMotive[0]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid sx={{ marginBottom: '20px' }}>
        <Typography color="primary" variant="h6" sx={AnnulmentStepsStyles.title} gutterBottom>
          ¿Desea anular o reagendar el servicio?
        </Typography>

        <Grid sx={AnnulmentStepsStyles.rescheduleParagraph}>
          <Typography color="primary" variant="subtitle1">
            Pruebe reagendar el servicio
          </Typography>
          <Button
            variant="text"
            onClick={redirectReschedule}
            disabled={disableReschedule}
            sx={AnnulmentStepsStyles.buttonLink}>
            aquí
          </Button>
          {disableReschedule && (
            <Typography sx={AnnulmentStepsStyles.disabledText} variant="subtitle1">
              (Solo se puede reagendar un servicio)
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: smUp ? 'row-reverse' : 'column',
          justifyContent: 'space-between',
          pt: 5,
        }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth={isMobile}
          onClick={onSubmit}
          disabled={loading}
          startIcon={
            loading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
          }>
          {loading ? 'cargando' : 'anular'}
        </Button>
        <Button color="inherit" onClick={handleGoHome} sx={{ mr: 1, mt: smUp ? 0 : 2 }}>
          Atrás
        </Button>
      </Box>
    </>
  );
}

export default CancellationReason;
