/* eslint-disable complexity */
import React, { useRef } from 'react';

/* Material UI */
import { Grid, Typography, Hidden } from '@mui/material';

/* Project */
import SchedulerStepsStyles from './SchedulerSteps.styles';
import StepsActionButtons from './components/StepsActionButtons';
import LocationForm from './components/LocationForm';

const map = `${window.location.origin}/images/mapa-salfa.png`;

function LocationStep() {
  const submitButtonRef = useRef(null);

  const handleNext = () => submitButtonRef.current.click();

  return (
    <>
      <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
        UBICACIÓN DEL SERVICIO
      </Typography>

      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={12} sm={12} md={5} id="form-section">
          <LocationForm submitButtonRef={submitButtonRef} />
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            id="image-section"
            style={{
              backgroundImage: `url(${map})`,
              backgroundPosition: 'center top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '400px',
              borderRadius: '5px',
              marginBottom: '10PX',
              minHeight: '400px',
            }}
            sx={SchedulerStepsStyles.imageBackground}
          />
        </Hidden>
      </Grid>
      <Grid item xs={12}>
        <StepsActionButtons handleNext={handleNext} />
      </Grid>
    </>
  );
}

export default LocationStep;
