import React, { useCallback } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

/* Project */
import useForm from 'hooks/useForm';
import { setActiveStep } from 'content/app.actions';
import { darkerGreyOnForms } from 'utils/helper';
import { controlsChanged } from '../schedulerSteps.actions';
import SchedulerStepsStyles from '../SchedulerSteps.styles';

const serviceTypeFieldLabelText = {
  maintenance: 'Mantención a agendar',
  diagnosis: 'Tipo de diagnóstico',
};

const serviceNotesFieldLabelText = {
  maintenance: 'Mencione si tiene un problema adicional que desee revisar',
  diagnosis: 'Descripción de la falla o problema del vehículo',
};

// TODO: change name of this component
function ServiceForm(props) {
  const { submitButtonRef } = props;

  const dispatch = useDispatch();

  const { controls } = useSelector((state) => state.schedulerSteps);
  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);
  const modelId = useSelector((state) => state.schedulerSteps.controls.modelId);
  const selectedModel = useSelector((state) => state.app.objects.vehicleModels?.[modelId]);

  const isMaintenanceService = selectedService === 'maintenance';

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  // // please do not remove this
  // const handleAutocompleteGetNewValue = ({ name, id }) => {
  //   dispatch(controlsChanged({ name, value: id }));
  // };

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      serviceKm: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Campo requerido',
        },
        {
          check: (value) => /^[0-9]+$/.test(value),
          message: 'Campo km solo admite números',
        },
      ],
      serviceType: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Campo requerido',
        },
      ],
      serviceNotes: [
        {
          check: (value) =>
            selectedService === 'maintenance' ? true : value.toString().trim().length > 0,
          message: 'Campo requerido',
        },
      ],
    },
  });

  const onChangeSelect = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange],
  );

  const types = isMaintenanceService
    ? Object.values(useSelector((state) => state.app.objects.kilometers)).filter(
        (k) => k.serialCode === selectedModel.serialKmCode,
      )
    : Object.values(useSelector((state) => state.app.objects.diagnosisTypes));
  const hiddenTypes = [
    { name: 'Campaña Recall  Outbound', externalId: 241 },
    { name: 'Campaña Embrague Camiones GM', externalId: 211 },
    { name: 'OBD II', externalId: 231 },
    { name: 'Campaña Inspeccion gratuita Toyota', externalId: 242 },
    { name: 'Campaña Inspeccion gratuita Nissan', externalId: 243 },
  ];
  const hiddenTypesIds = hiddenTypes.map((type) => type.externalId);
  const sortedTypes = types.length
    ? types
        .sort((a, b) => +a.name - +b.name)
        .filter((type) => !hiddenTypesIds.includes(type.externalId))
    : [];

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(() => {
      dispatch(setActiveStep());
    });
  };

  return (
    <Grid item xs={12} sm={12} md={6} id="form-section">
      <form onSubmit={handleSubmit}>
        <TextField
          id="serviceKm"
          name="serviceKm"
          onChange={onChange}
          value={controls.serviceKm}
          label="KM"
          inputProps={{ maxLength: 6 }}
          InputLabelProps={{
            sx: { ...darkerGreyOnForms },
          }}
          variant="outlined"
          size="small"
          sx={SchedulerStepsStyles.field}
          error={!!errors.serviceKm[0]}
          helperText={errors.serviceKm[0]}
          fullWidth
        />
        <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
          <InputLabel id="demo-simple-select-label" sx={{ ...darkerGreyOnForms }}>
            {serviceTypeFieldLabelText[selectedService]}
          </InputLabel>
          <Select
            id="serviceType"
            name="serviceType"
            onChange={onChangeSelect}
            value={controls.serviceType}
            error={!!errors.serviceType[0]}
            labelId="demo-simple-select-label"
            label="Mantención a agendar"
            fullWidth>
            {sortedTypes.length ? (
              sortedTypes.map((item) => (
                <MenuItem key={item.id} value={item.id} sx={SchedulerStepsStyles.menuItem}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem sx={SchedulerStepsStyles.menuItem} disabled>
                No hay datos disponibles.
              </MenuItem>
            )}
          </Select>
          <FormHelperText error>{errors.serviceType[0]}</FormHelperText>
        </FormControl>
        <TextField
          id="serviceNotes"
          name="serviceNotes"
          onChange={onChange}
          value={controls.serviceNotes}
          label={serviceNotesFieldLabelText[selectedService]}
          variant="outlined"
          size="small"
          multiline
          rows={7}
          InputLabelProps={{
            sx: { ...darkerGreyOnForms },
          }}
          sx={SchedulerStepsStyles.field}
          fullWidth
          error={!!errors.serviceNotes[0]}
          helperText={errors.serviceNotes[0]}
        />
        <input type="submit" style={{ display: 'none' }} ref={submitButtonRef} />
      </form>
    </Grid>
  );
}

export default ServiceForm;
