import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import StyledContainerStyles from './StyledContainer.styles';

function StyledContainer(props) {
  const { children, title = 'AUTO AGENDAMIENTO SERVICIO LIVIANO' } = props;
  return (
    <Grid container sx={StyledContainerStyles.container}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
        <Paper sx={StyledContainerStyles.paper}>{children}</Paper>
      </Grid>
    </Grid>
  );
}

export default StyledContainer;
