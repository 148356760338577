import React, { useCallback } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Grid } from '@mui/material';

/* Project */
import useForm from 'hooks/useForm';
import { setActiveStep } from 'content/app.actions';
import { controlsChanged, selectReschedule } from './schedulerSteps.actions';
import ResultsTable from './components/ResultsTable';
import InfoAlert from './components/InfoAlert';
import StepsActionButtons from './components/StepsActionButtons';

function SelectReschedule() {
  const dispatch = useDispatch();
  const controls = useSelector((state) => state.schedulerSteps.controls);

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const { onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      selectedReschedule: [
        {
          check: (value) => Object.keys(value).length > 0,
          message: 'Debe seleccionar una cita para reagendar',
        },
      ],
    },
  });

  const handleChangeTable = (value) => {
    dispatch(selectReschedule(value));
  };

  const handleNext = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(setActiveStep()));
  };

  return (
    <Grid>
      <form onSubmit={handleNext}>
        <Grid sx={{ marginBottom: '20px' }}>
          <ResultsTable onChange={handleChangeTable} controls={controls} errors={errors} />
        </Grid>
        <Grid sx={{ marginBottom: '20px' }}>
          <InfoAlert controls={controls} />
        </Grid>
        <Grid item xs={12}>
          <StepsActionButtons
            handleNext={handleNext}
            disabled={controls.selectedReschedule.length > 1}
          />
        </Grid>
      </form>
    </Grid>
  );
}

export default SelectReschedule;
