/* eslint-disable */
import React, { Children, useMemo } from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

/* React Big Calendar */
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendar.css';

/* MomentJS */
import moment from 'moment';
import 'moment/locale/es.js';

/* Project */
import SchedulerStepsStyles from '../schedulerSteps/SchedulerSteps.styles';
import CustomToolbar from './components/CustomToolbar';
import CalendarSkeleton from './components/CalendarSkeleton';
import availableHours from 'utils/availableHours';
import { useSelector } from 'react-redux';

const localizer = momentLocalizer(moment);
const unavailableDates = [
  moment('10/10/2022').startOf('day').toDate(),
  moment('10/12/2022').startOf('day').toDate(),
  moment('10/21/2022').startOf('day').toDate(),
];

function CustomCalendar(props) {
  const {
    date,
    setDate,
    currentDate,
    controls,
    toggleEditError,
    month,
    calendarLoading,
    holidays,
    reservations,
    technicianSchedule,
  } = props;
  const yesterday = moment().startOf('day').subtract(1, 'days').toDate();
  const startMonth = moment(currentDate).startOf('month').toDate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const messages = {
    date: 'Fecha',
    time: 'Tiempo',
    event: 'Evento',
    day: 'Day',
    month: 'Mes',
    previous: 'Atras',
    next: 'Siguiente',
    yesterday: 'Ayer',
    tomorrow: 'Mañana',
    today: 'Hoy',
    agenda: 'Agenda',

    showMore: (total) => `+${total} more`,
  };

  const holidaysData = useMemo(() => Object.keys(holidays).map((key) => holidays[key]), [holidays]);
  const formatedHolidays = holidaysData.map((item) => moment(item.date).startOf('day').toDate());
  const customDisableDates = process.env.REACT_APP_DISABLE_DATES
    ? JSON.parse(process.env.REACT_APP_DISABLE_DATES)
    : [];

  const customDisableDatesFormat = customDisableDates.map((item) =>
    moment(item).startOf('day').toDate(),
  );

  const { serviceHours, selectedTechnician } = controls;
  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);

  // Logs that shows service hours and selected tech
  console.log('serviceHours::: ', serviceHours);
  console.log('selectedTechnician:: ', selectedTechnician);

  const serviceHour = useMemo(() => {
    // * Formatea la hora que toma el servicio
    let hourSplit;
    if (serviceHours !== '') {
      hourSplit = serviceHours.split('.');
      let hour = Number(hourSplit[0]);
      let minutes = hourSplit[1];

      if (Number(minutes[0]) > 5) {
        hour = hour + 1;
        minutes = 0;
      } else if (Number(minutes[0]) == 5) {
        minutes = Number(minutes[0]);
      } else if (Number(minutes[0]) < 5 && Number(minutes[0]) !== 0) {
        minutes = 5;
      } else if (Number(minutes[0]) == 0) {
        minutes = Number(minutes[0]);
      }
      hourSplit = `${hour}.${minutes}`;
    }

    return hourSplit;
  }, [serviceHours]);

  const formatItemTime = (hour, selectedDate) => {
    const availableHourTime = hour.split(':');
    const formatedDate = moment(selectedDate)
      .set({
        hour: +availableHourTime[0],
        minute: +availableHourTime[1],
      })
      .format('YYYY-MM-DD HH:mm');
    const endHourService = moment(formatedDate)
      .add(Number(serviceHour) * 3600, 'second')
      .format('YYYY-MM-DD HH:mm');

    return { formatedDate, endHourService };
  };

  const disableBusyDay = (date) => {
    const selectedDate = date;

    let filteredReservations;
    let formatedReservations;
    if (reservations) {
      const selectedDateFormated = moment(selectedDate).format('YYYY-MM-DD');
      const filter = reservations.filter(
        (item) =>
          moment(selectedDateFormated).isBetween(
            moment.utc(item.start).format('YYYY-MM-DD'),
            moment.utc(item.end).format('YYYY-MM-DD'),
          ) ||
          moment(selectedDateFormated).isSame(moment.utc(item.start).format('YYYY-MM-DD')) ||
          moment(selectedDateFormated).isSame(moment.utc(item.end).format('YYYY-MM-DD')),
      );

      filteredReservations = filter.map((item) => {
        const formatedStartDate = moment.utc(item.start).local().format('YYYY-MM-DD HH:mm');
        const formatedEndDate = moment.utc(item.end).local().format('YYYY-MM-DD HH:mm');

        return { start: formatedStartDate, end: formatedEndDate };
      });

      formatedReservations = reservations.map((reservation) => {
        const formaStartDate = moment.utc(reservation.start).toDate();
        const formatEndDate = moment.utc(reservation.end).toDate();

        const date = moment(formaStartDate).format('YYYY-MM-DD');
        const start = moment(formaStartDate).format('HH:mm');
        const end = moment(formatEndDate).format('HH:mm');

        return { date, start, end };
      });
    } else {
      filteredReservations = [];
      formatedReservations = [];
    }
    // * Validates if an hour is busy or not from reservations
    const busyHours = availableHours.map((item) => {
      const availableHourTime = item.hour.split(':');
      const formatedDate = moment(selectedDate)
        .set({
          hour: +availableHourTime[0],
          minute: +availableHourTime[1],
        })
        .format('YYYY-MM-DD HH:mm');
      const endHourService = moment(formatedDate)
        .add(Number(serviceHour) * 3600, 'second')
        .format('YYYY-MM-DD HH:mm');

      const isBusy = [];

      filteredReservations.map((item, index) => {
        // * Removes one minute to end date to allow reservations in the next block
        const endDate = moment(item.end).subtract(60, 'seconds').format('YYYY-MM-DD HH:mm');

        if (moment(formatedDate).isSame(item.start) || moment(formatedDate).isSame(endDate)) {
          isBusy.push(true);
        } else if (moment(formatedDate).isBetween(item.start, endDate)) {
          isBusy.push(true);
        } else {
          isBusy.push(false);
        }
      });

      const availableType = isBusy.find((item) => item) ? 'not-available' : 'available';
      delete item.available;
      return {
        ...item,
        serviceHour,
        availableType,
        /* serviceHourFinalDate: moment(endDate).format('HH:mm'), */
      };
    });
    // * Adds hours if other hour is not available validation
    const previousHourValidation = busyHours.map(({ hour, availableType, showByDefault }) => {
      let validatUnavailableHour;
      if (hour === '10:30') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '10:00');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      } else if (hour === '11:30') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '11:00');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      } else if (hour === '17:00') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '16:30');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      } else {
        return {
          hour,
          availableType,
          showByDefault,
        };
      }
    });
    // * Validates past hours conditions
    const disablePastHours = previousHourValidation.map((item, index) => {
      const nextUnavailableBlock = previousHourValidation.find(
        (item) => item.availableType === 'not-available',
      );

      const unavailableBlocks = previousHourValidation.filter(
        (item) => item.availableType === 'not-available',
      );

      if (item.availableType !== 'not-available' && !!unavailableBlocks.length) {
        const nextBlockValidation = unavailableBlocks.map((blocks) => {
          const { formatedDate, endHourService } = formatItemTime(item.hour, selectedDate);

          const { formatedDate: unavailableBlockStart, endHourService: unavailableBlockEnd } =
            formatItemTime(blocks.hour, selectedDate);
          let unavailableTime;
          const endDate = moment(endHourService).subtract(60, 'seconds').format('YYYY-MM-DD HH:mm');

          if (
            moment(endDate).isSame(unavailableBlockStart) ||
            moment(endDate).isSame(unavailableBlockEnd)
          ) {
            unavailableTime = true;
          } else if (moment(endDate).isBetween(unavailableBlockStart, unavailableBlockEnd)) {
            unavailableTime = true;
          } else {
            unavailableTime = false;
          }
          return {
            ...item,
            availableType: unavailableTime ? 'not-available' : 'available',
            notByAvailablebyTime: true,
          };
        });

        const filterValidation = nextBlockValidation.filter((item) => item !== undefined);
        const errorExist = filterValidation.find((item) => item.availableType === 'not-available');

        return errorExist ? errorExist : item;
      } else {
        return item;
      }
    });
    // disableDailyLimitService;
    let disabledDates;
    if (selectedService === 'diagnosis') {
      const formatedDate = moment(selectedDate).format('YYYY-MM-DD');
      const filteredReservations = formatedReservations.filter(
        (reservation) => reservation.date === formatedDate,
      );

      disabledDates = filteredReservations.length >= Number(selectedTechnician.dailyLimitServices);
    } else {
      disabledDates = false;
    }
    // * Validates if an hour is busy or not from technician schedule
    const disableHoursServiceHour = disablePastHours.map((dph) => {
      const startDateHourService = moment(dph.hour, 'HH:mm')
        .add(60, 'seconds')
        .local()
        .format('HH:mm');
      const endDateHourService = moment(dph.hour, 'HH:mm')
        .add(Number(serviceHour) * 3600 - 60, 'seconds')
        .local()
        .format('HH:mm');
      const startDateService = moment(
        `${moment(selectedDate).format('YYYY-MM-DD')} ${startDateHourService}`,
      );
      const endDateService = moment(
        `${moment(selectedDate).format('YYYY-MM-DD')} ${endDateHourService}`,
      );

      if (technicianSchedule && selectedTechnician !== '') {
        technicianSchedule.forEach((schedule) => {
          const day = moment(selectedDate).format('dddd').toLowerCase();
          // const scheduleTimes = [];
          if (schedule.scheduleDays.includes(day)) {
            const scheduleTimes = schedule.scheduleTimes.map((time) => {
              const startTimeSchedule = moment(
                `${moment(selectedDate).format('YYYY-MM-DD')} ${time.startTime}`,
              ).local();
              const endTimeSchedule = moment(
                `${moment(selectedDate).format('YYYY-MM-DD')} ${time.endTime}`,
              ).local();

              return (
                startDateService.isBetween(startTimeSchedule, endTimeSchedule, undefined, '[]') &&
                endDateService.isBetween(startTimeSchedule, endTimeSchedule, undefined, '[]')
              );
            });
            //check if scheduleTime have all values to false
            if (scheduleTimes.every((item) => !item)) {
              dph.availableType = 'not-available';
            } else {
              dph.availableType = 'available';
            }
          }
        });
      }
      return dph;
    });
    const isBusy =
      disableHoursServiceHour.filter((item) => item.availableType === 'available').length == 0;

    return isBusy || disabledDates;
  };

  const isDayFullyBooked = (date, technicianSchedule, reservations, serviceHour) => {
    const startOfDay = moment(date).startOf('day');
    const endOfDay = moment(date).endOf('day');
    console.log('Date', date);
    console.log('technicianSchedule', technicianSchedule);
    console.log('serviceHour', serviceHour);

    if (!reservations) {
      return false;
    }

    const technicianReservations = reservations.filter(
      (reservation) =>
        moment(reservation.start).isBefore(endOfDay) && moment(reservation.end).isAfter(startOfDay),
    );

    console.log('technicianReservations', technicianReservations);

    if (technicianReservations.length === 0) {
      return false;
    }

    let totalBookedMinutes = 0;
    technicianReservations.forEach((reservation) => {
      const start = moment(reservation.start);
      const end = moment(reservation.end);
      totalBookedMinutes += end.diff(start, 'minutes');
    });

    const technicianWorkingMinutes = technicianSchedule.reduce((totalMinutes, schedule) => {
      const day = startOfDay.format('dddd').toLowerCase();
      if (schedule.scheduleDays.includes(day)) {
        schedule.scheduleTimes.forEach((time) => {
          const start = moment(time.startTime, 'HH:mm');
          const end = moment(time.endTime, 'HH:mm');
          totalMinutes += end.diff(start, 'minutes');
        });
      }
      return totalMinutes;
    }, 0);

    const serviceMinutes = serviceHour * 60;

    console.log('totalBookedMinutes', totalBookedMinutes);
    console.log('technicianWorkingMinutes', technicianWorkingMinutes);
    console.log('serviceMinutes', serviceMinutes);
    return totalBookedMinutes + serviceMinutes >= technicianWorkingMinutes;
  };

  const DateCellWrapper = (props) => {
    const { children, value } = props;
    const valueData = moment(value).format('YYYY-MM-DD');
    const isUnavailable = disableBusyDay(value);
    const isToday = new Date(value).valueOf() === new Date(currentDate).valueOf();
    const isSelected = new Date(value).valueOf() === new Date(date).valueOf();
    const isPastDay = new Date(value).valueOf() <= yesterday.valueOf();
    const isPastMonth = value < startMonth.valueOf();
    const isFullyBooked = isDayFullyBooked(value, technicianSchedule, reservations, serviceHour);

    const isHoliday = formatedHolidays.find(
      (item) => valueData === moment(item).format('YYYY-MM-DD'),
    );

    const customDisabled = customDisableDatesFormat.find(
      (item) => valueData === moment(item).format('YYYY-MM-DD'),
    );

    const getDay = moment(value).format('dddd');
    const weekDay = getDay !== 'sábado' && getDay !== 'domingo';

    const selectDate = (value) => {
      if (controls.selectedHour !== '') {
        toggleEditError();
      } else {
        const today = moment(new Date());
        const selectedDate = moment(value);

        if (moment(selectedDate).isBefore(today)) {
          setDate(null);
        } else if (isToday) {
          setDate(null);
        } else {
          setDate(value);
        }
      }
    };

    return (
      weekDay &&
      React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: isPastMonth
            ? '#E5E7E9'
            : isHoliday
            ? '#E5E7E9'
            : customDisabled
            ? '#E5E7E9'
            : isPastDay
            ? '#E5E7E9'
            : isToday
            ? '#E5E7E9'
            : isFullyBooked
            ? '#FD9090'
            : isUnavailable && !isPastDay
            ? '#FD9090'
            : weekDay
            ? '#fff'
            : '#E5E7E9',
          outline:
            isSelected && !isPastDay && !isUnavailable && weekDay && !isHoliday
              ? '2px solid #2196F3'
              : '',
          outlineOffset: '-2px',
          cursor:
            isPastMonth || isHoliday || isToday || isPastDay || isUnavailable || isFullyBooked ? 'auto' : 'pointer',
          '&.rbcDateCell .rbcButtonLink': {
            color: 'purple',
          },
        },
        onClick: () => {
          if (!isUnavailable && !isHoliday && !isFullyBooked) {
            selectDate(value);
          }
        },
      })
    );
  };

  const firstVisibleDay = localizer.firstVisibleDay();
  const lastVisibleDay = localizer.lastVisibleDay();

  return (
    <>
      <Grid sx={{ marginBottom: '20px' }}>
        {calendarLoading ? (
          <CalendarSkeleton isMobile={isMobile} />
        ) : (
          <Calendar
            localizer={localizer}
            defaultDate={month}
            defaultView="month"
            style={isMobile ? { minHeight: '50vh' } : { minHeight: '650px' }}
            messages={messages}
            views={['month']}
            selectable
            components={{
              dateCellWrapper: DateCellWrapper,
              toolbar: CustomToolbar,
            }}
            min={currentDate}
          />
        )}

        <Grid sx={{ marginTop: '20px' }}>
          <Grid sx={SchedulerStepsStyles.infoContainer}>
            <Grid sx={SchedulerStepsStyles.availableBox} />
            <Typography color="primary" variant="body2" sx={SchedulerStepsStyles.infoText}>
              Disponible
            </Typography>
          </Grid>
          <Grid sx={SchedulerStepsStyles.infoContainer}>
            <Grid sx={SchedulerStepsStyles.noDateAvailable} />
            <Typography color="primary" variant="body2" sx={SchedulerStepsStyles.infoText}>
              Ocupado
            </Typography>
          </Grid>
          <Grid sx={SchedulerStepsStyles.infoContainer}>
            <Grid sx={SchedulerStepsStyles.selectedBox} />
            <Typography color="primary" variant="body2" sx={SchedulerStepsStyles.infoText}>
              Seleccionado
            </Typography>
          </Grid>
          <Grid sx={SchedulerStepsStyles.infoContainer}>
            <Grid sx={SchedulerStepsStyles.unavailableBox} />
            <Typography color="primary" variant="body2" sx={SchedulerStepsStyles.infoText}>
              No disponible
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CustomCalendar;
