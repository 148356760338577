import React, { useDebugValue, useEffect } from 'react';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import CancelAppointment from 'content/features/annulmentSteps/CancelAppointment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';

function Annulment({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAnnulmentState());
  }, []);

  const view = useSelector((state) => state.app.view);

  useEffect(() => {
    if (view === '') {
      navigate('/annulment');
    }
  }, [view]);

  return (
    <StyledContainer title={title}>
      <CancelAppointment />
    </StyledContainer>
  );
}

export default Annulment;
