const pageSelector = (state) => ({
  controls: state.register.controls,
  loading: state.register.loading,
});

const RegisterSelector = {
  page: pageSelector,
};

export default RegisterSelector;
