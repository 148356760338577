import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetConfirmState } from '../confirm.actions';
import confirmCardStyles from '../confirmCard.styles';

function ValidationCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { date, tallerNombre } = useSelector((state) => state.confirm.data);

  return (
    <Grid container direction="column" alignItems="center" sx={confirmCardStyles.card}>
      <Grid item padding={4}>
        <CheckCircleOutlineIcon sx={{ color: '#039100', width: '100px', height: '100px' }} />
      </Grid>
      <Grid item paddingBottom={1}>
        <Typography sx={confirmCardStyles.titleCard}>Reserva confirmada</Typography>
      </Grid>
      <Grid item>
        <Typography alignContent="center" sx={confirmCardStyles.validationText}>
          Muchas gracias por confirmar tu reserva. Te esperamos el{' '}
          <strong>{moment.utc(date).local().format('DD-MM-YYYY')}</strong> a las{' '}
          <strong>{moment.utc(date).local().format('HH:mm')}</strong> en{' '}
          <strong>{tallerNombre}</strong>.
        </Typography>
      </Grid>
      <Grid item container padding={4}>
        <Button
          variant="contained"
          sx={confirmCardStyles.button.contained}
          fullWidth
          onClick={() => {
            dispatch(resetConfirmState());
            navigate('/');
          }}>
          Ir a inicio
        </Button>
      </Grid>
    </Grid>
  );
}

export default ValidationCard;
