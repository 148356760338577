const textImageValidationSelectedService = {
  maintenance: {
    textValidation: 'Mantenciones',
    textValidationImage:
      'Mantenimiento preventivo y de rutina del vehículo según pauta autorizada por la marca.                             ',
  },
  diagnosis: {
    textValidation: 'Diagnóstico',
    textValidationImage:
      'Inspección de vehículo para identificar la falla o problema, posteriormente se deriva a una reparación.',
  },
};

export default textImageValidationSelectedService;
