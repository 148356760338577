import React, { useEffect } from 'react';

/* Project */
import SuccessScreen from 'content/features/schedulerSteps/SuccessScreen';
import StyledContainer from 'content/containers/StyledContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAnnulmentDocument } from 'content/features/annulmentSteps/annulmentSteps.actions';
import moment from 'moment';

function AnnulmentVoucher({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const annulmentResponse = useSelector((state) => state.annulmentSteps.controls.annulmentResponse);
  const loading = useSelector((state) => state.annulmentSteps.loading);
  const { ticketId, serviceDate, workshop, serviceType, client, annulmentMotive } =
    annulmentResponse;
  useEffect(() => {
    if (Object.getOwnPropertyNames(annulmentResponse).length === 0) {
      navigate('/annulment');
    }
  }, [annulmentResponse]);

  const annulmentData = {
    type: 'annulment',
    id: ticketId,
    date: moment(serviceDate).format('DD/MM/YYYY - HH:mm'),
    place: workshop,
    service: serviceType,
    userName: client,
    errorMotive: annulmentMotive,
  };

  const downloadVoucher = () => {
    const selectedDateOffset = moment(serviceDate).utcOffset() / 60;
    dispatch(
      getAnnulmentDocument({
        ticketId,
        annulmentMotive,
        offset: selectedDateOffset,
      }),
    );
  };

  return (
    <StyledContainer title={title}>
      <SuccessScreen data={annulmentData} loading={loading} downloadVoucher={downloadVoucher} />
    </StyledContainer>
  );
}

export default AnnulmentVoucher;
