import * as React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useTheme,
  Hidden,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
/* React Router */
import { useNavigate } from 'react-router-dom';

/* Project */
import Paths from 'utils/paths';
import { useDispatch } from 'react-redux';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { resetStepsState } from 'content/features/schedulerSteps/schedulerSteps.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';

/* Public */
// import TCITLogo from '/assets/images/TcitLogo_123x50.png';

function TemporaryDrawer(props) {
  const { side, openByDefault, setOpenDrawer } = props;
  const theme = useTheme();

  const SalfaLogo = `${window.location.origin}/images/salfa-logo.png`;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabValidation = (event) => event.key === 'Tab' || event.key === 'Shift';
  const toggleValidations = (event) => event.type === 'keydown' && tabValidation(event);

  const toggleDrawer = (anchor, open) => (event) => {
    if (toggleValidations(event)) return;

    setState({ ...state, [anchor]: open });
  };

  const handleRouteClick = (path) => {
    dispatch(resetWelcomeScreenControls());
    dispatch(resetStepsState());
    dispatch(resetAnnulmentState());
    navigate(path);
    setOpenDrawer(false);
  };

  const { publics } = Paths;

  const views = [...publics]
    .filter((path) => path.name && !path.hideInDrawer)
    .map(({ Icon, path, name }) => (
      <ListItem key={path} disablePadding>
        <ListItemButton onClick={() => handleRouteClick(path)}>
          <ListItemIcon>{Icon ? <Icon /> : null}</ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    ));

  const list = (anchor) => (
    <Box
      sx={{ width: ['top', 'bottom'].includes(anchor) ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Hidden smUp>
        <Paper
          sx={{
            height: '56px',
            background: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          elevation={2}
          square>
          <IconButton
            sx={{ paddingLeft: '10px', marginLeft: '10px' }}
            data-testid="toolbar-menu-button"
            onClick={() => setOpenDrawer(false)}
            edge="start"
            color="inherit"
            aria-label="menu">
            <MenuIcon sx={{ color: '#fff' }} />
          </IconButton>
          <IconButton>
            <img style={{ height: '25px' }} src={SalfaLogo} alt="" />
          </IconButton>
        </Paper>
      </Hidden>
      <Hidden smDown>
        <Paper
          sx={{
            height: '64px',
            background: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          elevation={2}
          square>
          <IconButton
            sx={{ paddingLeft: '10px', marginLeft: '10px' }}
            data-testid="toolbar-menu-button"
            onClick={() => setOpenDrawer(false)}
            edge="start"
            color="inherit"
            aria-label="menu">
            <MenuIcon sx={{ color: '#fff' }} />
          </IconButton>
          <IconButton>
            <img style={{ height: '25px' }} src={SalfaLogo} alt="" />
          </IconButton>
        </Paper>
      </Hidden>

      <Divider />
      <List>{views}</List>
    </Box>
  );

  return (
    <Drawer
      anchor={side}
      open={openByDefault}
      onClose={() => setOpenDrawer(false)}
      test-dataid="app-drawer">
      {list(side)}
    </Drawer>
  );
}

TemporaryDrawer.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  side: PropTypes.string,
  openByDefault: PropTypes.bool,
};

TemporaryDrawer.defaultProps = {
  side: 'left',
  openByDefault: false,
};

export default TemporaryDrawer;
