/* eslint-disable complexity */
import * as React from 'react';

/* Material UI */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

/* Project */
import { goToSchedulerStep } from 'content/app.actions';
import { useDispatch } from 'react-redux';
import { viewTypes } from 'utils/scheduleTypes';
import { clearScheduleData } from '../schedulerSteps/schedulerSteps.actions';
import DialogStyles from './Dialogs.styles';

function ErrorDialog(props) {
  const { open, handleClose, errorType, view } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const salfaClickToCallLink = process.env.REACT_APP_LINK_CLICK_TO_CALL;

  const redirectSchedulerView = () => {
    const redirectStep = view === 'schedule' ? 4 : 1;
    dispatch(clearScheduleData());
    dispatch(goToSchedulerStep({ activeStep: redirectStep }));
  };

  const errorTypes = {
    'no-records': {
      firstParagraph:
        'Lo sentimos, no se ha encontrado ningún agendamiento o agendamiento activo con los datos ingresados, puedes contactarte con uno de nuestros ejecutivos.',
      secondParagraph: '',
      customActionButton: null,
      showPhone: true,
    },
    'server-error': {
      secondParagraph:
        'Se ha producido un error inesperado, puedes contactarte con uno de nuestros ejecutivos.',
      customActionButton: null,
      showPhone: true,
    },
    'time-limit': {
      firstParagraph: (
        <>
          Lo sentimos, No se puede anular un agendamiento con menos de <strong>24 horas</strong> de
          anticipación.
        </>
      ),
      secondParagraph: 'Puedes contactarte con uno de nuestros ejecutivos.',
      customActionButton: null,
      showPhone: true,
    },
    'date-taken': {
      firstParagraph: `Lo sentimos, ha ocurrido un error en ${viewTypes[view].grammar} ${viewTypes[view].title}.`,
      secondParagraph: 'Puedes contactarte con uno de nuestros ejecutivos.',
      // TODO: ACCION DE REDUX PARA REDIRIGIR AL PASO
      customActionButton: (
        <Button color="primary" variant="contained" fullWidth onClick={redirectSchedulerView}>
          seleccionar horario
        </Button>
      ),
      showPhone: true,
    },
    'technician-error': {
      secondParagraph:
        'El Técnico seleccionado no tiene las capacidades configuradas, contacte un ejecutivo',
      customActionButton: null,
      showPhone: true,
    },
  };

  const closeButton = (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <Button onClick={handleClose} fullWidth={isMobile}>
        cerrar
      </Button>
    </Grid>
  );

  const selectHour = (
    <Grid
      item
      xs={12}
      sm={12}
      md={errorTypes[errorType]?.customActionButton ? 6 : 0}
      sx={{ background: 'yellow' }}>
      {errorTypes[errorType]?.customActionButton}
    </Grid>
  );

  const dialogContent = (
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={DialogStyles.dialogContent}>
        {errorTypes[errorType]?.firstParagraph}
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={DialogStyles.dialogContent}>
        {errorTypes[errorType]?.secondParagraph}
      </DialogContentText>
      {errorTypes[errorType]?.showPhone && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.open(salfaClickToCallLink, '_blank', 'noopener noreferrer')}
            fullWidth={isMobile}>
            Contactar ejecutivo
          </Button>
        </Grid>
      )}
    </DialogContent>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle id="alert-dialog-title" sx={DialogStyles.dialogTitle}>
        <Grid container direction="column" alignItems="center">
          <InfoOutlinedIcon sx={DialogStyles.errorIcon} />
          ERROR EN{' '}
          {view === 'annulment' ? `${viewTypes[view].title} de agenda` : viewTypes[view].title}
        </Grid>
      </DialogTitle>
      <Hidden smUp>
        <Grid>{dialogContent}</Grid>
      </Hidden>
      <Hidden smDown>
        <Grid sx={{ padding: '0px 40px' }}>{dialogContent}</Grid>
      </Hidden>
      <DialogActions sx={{ padding: '0px 20px 0px 20px', marginBottom: '20px' }}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Hidden mdUp>
            {selectHour}
            {closeButton}
          </Hidden>
          <Hidden mdDown>
            {closeButton}
            {selectHour}
          </Hidden>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
