const BASE = '[UserTasks]';

const UserTasksTypes = {
  FETCH_TASKS: `${BASE} FETCH_TODOLIST`,
  FETCH_TASKS_SUCCESS: `${BASE} FETCH_TODOLIST_SUCCESS`,
  FETCH_TASKS_FAIL: `${BASE} FETCH_TODOLIST_FAIL`,

  CREATE_TASK: `${BASE} CREATE_TODO`,
  CREATE_TASK_SUCCESS: `${BASE} CREATE_TODO_SUCCESS`,
  CREATE_TASK_FAIL: `${BASE} CREATE_TODO_FAIL`,

  CHECK_TASK: `${BASE} CHECK_TODO`,
  CHECK_TASK_SUCCESS: `${BASE} CHECK_TODO_SUCCESS`,
  CHECK_TASK_FAIL: `${BASE} CHECK_TODO_FAIL`,

  DELETE_TASK: `${BASE} DELETE_TASK`,
  DELETE_TASK_SUCCESS: `${BASE} DELETE_TASK_SUCCESS`,
  DELETE_TASK_FAIL: `${BASE} DELETE_TASK_FAIL`,
  CHANGE_CONTROLS: `${BASE} CHANGE_CONTROLS`,
  CHANGE_ACTIONS: `${BASE} CHANGE_ACTIONS`,
};

export default UserTasksTypes;
