import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* Project */
import Loader from 'content/shared/Loader/Loader';
import { getScheduleStateFromApi, setView } from 'content/app.actions';
import ScheduleWelcomeButton from './ScheduleWelcomeButton';
import SelectServiceInputButton from './SelectServiceInputButton';
import { controlsChanged } from '../welcomeScreens.actions';
import homeStyles from '../home.styles';
import '../styles.css';

function ScheduleWelcome() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const objects = useSelector((state) => state.app.objects);
  const requestLoading = useSelector((state) => state.app.requestLoading);

  useEffect(() => {
    dispatch(getScheduleStateFromApi());
  }, []);

  const services = useMemo(
    () => Object.keys(objects?.services).map((key) => objects?.services[key]),
    [objects],
  );

  const onChangeText = useCallback((data) => {
    const { name, value } = data.target;
    dispatch(controlsChanged({ name, value }));
  }, []);

  const handleSubmit = () => {
    dispatch(setView({ value: 'schedule' }));
    navigate('schedule/steps');
  };

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setDivHeight(ref?.current?.clientHeight || 0);
  }, []);

  return (
    <Grid>
      <Loader loading={requestLoading} height={divHeight}>
        <Grid ref={ref}>
          <Grid>
            <Typography color="primary" variant="h6" sx={homeStyles.title}>
              Bienvenido/a
            </Typography>
            <Typography color="primary">
              Selecciona el tipo de servicio que deseas agendar.
            </Typography>
          </Grid>

          <SelectServiceInputButton
            isMobile={isMobile}
            services={services}
            onChangeText={onChangeText}
            homeStyles={homeStyles}
          />

          <Grid item xs={12} sx={homeStyles.actionsContainer}>
            <ScheduleWelcomeButton isMobile={isMobile} handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Loader>
    </Grid>
  );
}

export default ScheduleWelcome;
