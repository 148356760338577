const BASE = '[Login]';

const LoginTypes = {
  LOGIN_USER: `${BASE} FETCH_LOGIN_USER`,
  LOGIN_USER_SUCCESS: `${BASE} FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${BASE} FETCH_LOGIN_USER_FAIL`,
  GOOGLE_LOGIN: `${BASE} GOOGLE_LOGIN`,
  GOOGLE_LOGIN_SUCCESS: `${BASE} GOOGLE_LOGIN_SUCCESS`,
  GOOGLE_LOGIN_FAIL: `${BASE} GOOGLE_LOGIN_FAIL`,
  DISABLE_ERRORS: `${BASE} DISABLE_ERRORS`,
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  LOGOUT: `${BASE} LOGOUT`,
  LOGOUT_SUCCESS: `${BASE} LOGOUT_SUCCESS`,
};

export default LoginTypes;
