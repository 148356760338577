import React, { useState } from 'react';

/* Material UI */
import { Grid, Typography } from '@mui/material';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Project */
import { CounterActions, CounterSelector } from 'content/features/counter';

const { increment, decrement, incrementByAmount, incrementAsync } = CounterActions;
const { selectCount } = CounterSelector;

function Counter() {
  const counter = useSelector(selectCount);
  const dispatch = useDispatch();
  const [incrementAmount, setIncrementAmount] = useState(1);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography color="text.title" variant="h3">
          Counter
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={1}>
            <button
              type="button"
              className="button"
              aria-label="Increment value"
              onClick={() => dispatch(increment())}>
              +
            </button>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h2" color="text.number" data-testid="counter-value">
              {counter}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <button
              type="button"
              className="button"
              aria-label="Decrement value"
              onClick={() => dispatch(decrement())}>
              -
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <input
          data-testid="increment-amount-input"
          className="textbox"
          aria-label="Set increment amount"
          value={incrementAmount}
          onChange={(e) => setIncrementAmount(e.target.value)}
        />
        <button
          type="button"
          className="button"
          onClick={() => dispatch(incrementByAmount({ amount: Number(incrementAmount) }))}>
          Add Amount
        </button>
        <button
          type="button"
          className="button asyncButton"
          onClick={() => dispatch(incrementAsync({ amount: Number(incrementAmount) }))}>
          Add Async
        </button>
      </Grid>
    </Grid>
  );
}

export default Counter;
