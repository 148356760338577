const loginStyles = {
  card: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginTop: '5%',
  },
  image: {
    width: '280px',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
    maxWidth: '100%',
  },
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
  },
  textfield: {
    marginTop: '10px',
  },
  button: {
    width: '280px',
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '10%',
  },
};

export default loginStyles;
