/* React Redux */
import { createReducer } from '@reduxjs/toolkit';

/* Project */
import {
  cancelAppointment,
  cancelAppointmentSuccess,
  clearErrorDialog,
  closeAppointmentInfo,
  controlsChanged,
  openAppointmentInfo,
  resetAnnulmentState,
  selectAnnulment,
  toggleErrorDialog,
} from './annulmentSteps.actions';

const initialState = {
  controls: {
    selectedAnnulments: {},
    annulmentMotive: '',
    appointmentInfo: false,
    annulmentResponse: {},
  },
  loading: false,
  error: false,
};

const AnnulmentStepsReducer = createReducer(initialState, (builder) => {
  builder.addCase(controlsChanged, (state, action) => {
    state.controls = {
      ...state.controls,
      ...action.payload,
    };
  });

  builder.addCase(selectAnnulment, (state, { payload }) => {
    state.controls.selectedAnnulments = payload;
  });
  builder.addCase(openAppointmentInfo, (state, { payload }) => {
    state.controls.appointmentInfo = payload;
  });
  builder.addCase(closeAppointmentInfo, (state) => {
    state.controls.appointmentInfo = false;
  });
  builder.addCase(resetAnnulmentState, (state) => {
    state.controls = initialState.controls;
    state.loading = false;
    state.error = false;
  });
  builder.addCase(cancelAppointment, (state) => {
    state.loading = true;
  });
  builder.addCase(cancelAppointmentSuccess, (state, { payload }) => {
    state.loading = false;
    state.controls.annulmentResponse = payload.serviceData;
  });
  builder.addCase(toggleErrorDialog, (state) => {
    state.error = true;
    state.loading = false;
  });
  builder.addCase(clearErrorDialog, (state) => {
    state.error = false;
  });
});

export default AnnulmentStepsReducer;
