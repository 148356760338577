import React from 'react';
import PropTypes from 'prop-types';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Project */
import { ConfirmDialog } from 'content/shared';
import { removeTask } from 'content/features/tasks/userTasks.actions';
import { actionsTaskSelector } from 'content/features/tasks/userTasks.selector';

function UserTaskDelete(props) {
  const { setTaskToRemove } = props;

  const dispatch = useDispatch();
  const taskToRemove = useSelector(actionsTaskSelector);

  const closeConfirmDialog = () => {
    setTaskToRemove(null);
  };

  const handleRemoveTask = () => {
    dispatch(removeTask({ task: taskToRemove }));
  };

  return (
    <ConfirmDialog
      open={!!taskToRemove?.id}
      message={`¿Estás seguro de que quieres eliminar la tarea: ${taskToRemove?.description}?`}
      severity="warning"
      onCloseConfirm={() => handleRemoveTask()}
      onClose={closeConfirmDialog}
    />
  );
}

UserTaskDelete.propTypes = {
  setTaskToRemove: PropTypes.func.isRequired,
};

export default UserTaskDelete;
