import React, { useCallback } from 'react';

/* React redux */
import { useDispatch } from 'react-redux';

/* React router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { Grid, Paper, Typography, Button, Hidden, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HandymanIcon from '@mui/icons-material/Handyman';
import HomeIcon from '@mui/icons-material/Home';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/* Project */
import { voucherTitles } from 'utils/scheduleTypes';
import Loader from './components/Loader';
import { resetStepsState } from './schedulerSteps.actions';
import { resetWelcomeScreenControls } from '../welcomeScreens/welcomeScreens.actions';
import SchedulerStepsStyles from './SchedulerSteps.styles';

function SuccessScreen({ data, downloadVoucher, loading }) {
  const {
    type,
    id,
    date,
    place,
    service,
    userName,
    technician,
    errorMotive,
    rescheduleOldDate,
    rescheduleNewDate,
  } = data;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectHome = () => {
    dispatch(resetStepsState());
    dispatch(resetWelcomeScreenControls());
    navigate('/');
  };

  const dateSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <DateRangeIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        {date}
      </Typography>
    </Grid>
  );

  const placeSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <LocationOnOutlinedIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        {place}
      </Typography>
    </Grid>
  );

  const serviceSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <BuildOutlinedIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        Servicio : {`${service === 'maintenance' ? 'Mantenciones' : 'Diagnóstico'}`}
      </Typography>
    </Grid>
  );

  const userSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <PersonOutlineOutlinedIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        {userName}
      </Typography>
    </Grid>
  );

  const motiveSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <InfoOutlinedIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        MOTIVO: {errorMotive}
      </Typography>
    </Grid>
  );

  const technicianSection = (
    <Grid item xs={12} sm={8} md={7} lg={5} xl={3} sx={SchedulerStepsStyles.successItemContainer}>
      <HandymanIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
      <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
        {technician}
      </Typography>
    </Grid>
  );

  const oldDate = (
    <>
      <Hidden smUp>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          lg={5}
          xl={3}
          sx={SchedulerStepsStyles.successItemContainer}>
          <DateRangeIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
          <Typography color="primary" variant="subtitle1" sx={SchedulerStepsStyles.rescheduleText}>
            Anterior:
          </Typography>
          <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
            {rescheduleOldDate}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Tooltip title="Fecha anterior" placement="top">
          <Grid item xs={6} sx={SchedulerStepsStyles.rescheduleDateContainer}>
            <DateRangeIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
            <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
              {rescheduleOldDate}
            </Typography>
          </Grid>
        </Tooltip>
      </Hidden>
    </>
  );

  const newDate = (
    <>
      <Hidden smUp>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          lg={5}
          xl={3}
          sx={SchedulerStepsStyles.successItemContainer}>
          <DateRangeIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
          <Typography color="primary" variant="subtitle1" sx={SchedulerStepsStyles.rescheduleText}>
            Nueva:
          </Typography>
          <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
            {rescheduleNewDate}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Tooltip title="Fecha nueva" placement="top">
          <Grid item xs={6} sx={SchedulerStepsStyles.rescheduleDateContainer}>
            <DateRangeIcon color="primary" sx={SchedulerStepsStyles.successIcon} />
            <Typography variant="subtitle1" sx={SchedulerStepsStyles.successText}>
              {rescheduleNewDate}
            </Typography>
          </Grid>
        </Tooltip>
      </Hidden>
    </>
  );

  const rescheduleDateSection = (
    <>
      <Hidden smDown>
        <Grid
          item
          xs={12}
          sx={SchedulerStepsStyles.successItemContainer}
          style={{ justifyContent: 'center' }}>
          <Grid
            sx={{
              width: '450px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'center',
            }}>
            {oldDate}
            <ArrowRightAltIcon color="primary" sx={{ margin: '0px 10px', fontSize: 40 }} />
            {newDate}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container sx={SchedulerStepsStyles.successItemContainer}>
          <Grid item xs={12}>
            {oldDate}
          </Grid>
          <Grid item xs={12}>
            {newDate}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );

  const scheduleInfo = (
    <>
      {dateSection}
      {placeSection}
      {serviceSection}
      {userSection}
      {technicianSection}
    </>
  );
  const annulmentInfo = (
    <>
      {dateSection}
      {placeSection}
      {serviceSection}
      {userSection}
      {motiveSection}
    </>
  );
  const rescheduleInfo = (
    <>
      {rescheduleDateSection}
      {placeSection}
      {serviceSection}
      {userSection}
      {technicianSection}
    </>
  );

  const views = {
    schedule: { component: scheduleInfo },
    annulment: { component: annulmentInfo },
    reschedule: { component: rescheduleInfo },
  };

  const getStepContent = useCallback(() => {
    const { component } = views[type];

    return component || null;
  }, [type]);

  return (
    <Grid>
      <Loader loading={loading}>
        <>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={8}>
              <Paper sx={SchedulerStepsStyles.successCard}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 80 }} />
                  <Typography
                    color="primary"
                    variant="h6"
                    gutterBottom
                    sx={{ marginBottom: '20px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                    {voucherTitles[type].title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                    ID TICKET #{id}
                  </Typography>
                </Grid>
                <Grid container sx={SchedulerStepsStyles.successContainer}>
                  {getStepContent()}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            sx={SchedulerStepsStyles.sucessActionsContainer}
            direction="row"
            justifyContent="flex-end">
            <Hidden smUp>
              <>
                <Grid item xs={12} sm={5} lg={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<HomeIcon />}
                    onClick={redirectHome}
                    sx={SchedulerStepsStyles.sucessActionsButton}>
                    VOLVER A INICIO
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5} lg={3}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<DownloadIcon />}
                    sx={SchedulerStepsStyles.sucessActionsButton}
                    onClick={downloadVoucher}>
                    DESCARGAR COMPROBANTE
                  </Button>
                </Grid>
              </>
            </Hidden>
            <Hidden smDown>
              <>
                <Grid item xs={12} sm={5} lg={3} sx={{ marginRight: '20px' }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<DownloadIcon />}
                    sx={SchedulerStepsStyles.sucessActionsButton}
                    onClick={downloadVoucher}>
                    DESCARGAR COMPROBANTE
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5} lg={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<HomeIcon />}
                    onClick={redirectHome}
                    sx={SchedulerStepsStyles.sucessActionsButton}>
                    VOLVER A INICIO
                  </Button>
                </Grid>
              </>
            </Hidden>
          </Grid>
        </>
      </Loader>
    </Grid>
  );
}

export default SuccessScreen;
