import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grow,
  Slide,
} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/* React Redux */
import alertDialogStyles from './AlertDialog.styles';

// eslint-disable-next-line
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const componentIcon = {
  info: <InfoOutlinedIcon sx={alertDialogStyles.info} />,
  success: <CheckCircleOutlineOutlinedIcon sx={alertDialogStyles.success} />,
  warning: <ReportProblemOutlinedIcon sx={alertDialogStyles.warning} />,
  error: <ErrorOutlineOutlinedIcon sx={alertDialogStyles.error} />,
};

function ConfirmDialog(props) {
  const { open, message, severity, onClose, onCloseConfirm, confirmButtonColor } = props;

  const icon = (type) => componentIcon[type] || null;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grow in timeout={900}>
          <div style={alertDialogStyles.iconContainer}>{icon(severity)}</div>
        </Grow>
        {message && (
          <Fade in timeout={900}>
            <DialogTitle style={alertDialogStyles.title}>{message}</DialogTitle>
          </Fade>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button onClick={onCloseConfirm} variant="contained" color={confirmButtonColor}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
  confirmButtonColor: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  onClose: PropTypes.func.isRequired,
  onCloseConfirm: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  open: false,
  message: '',
  severity: 'info',
  confirmButtonColor: 'error',
};

export default ConfirmDialog;
