import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetConfirmState } from '../confirm.actions';
import confirmCardStyles from '../confirmCard.styles';

function FailedCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const salfaClickToCallLink = process.env.REACT_APP_LINK_CLICK_TO_CALL;

  return (
    <Grid container direction="column" alignItems="center" sx={confirmCardStyles.card}>
      <Grid item padding={4}>
        <ErrorOutlineIcon sx={{ color: '#E10600', width: '100px', height: '100px' }} />
      </Grid>
      <Grid item paddingBottom={1}>
        <Typography sx={confirmCardStyles.titleCard}>Ha ocurrido un inconveniente</Typography>
      </Grid>
      <Grid item sx={{ padding: { xs: '2px', sm: '0px' } }}>
        <Typography alignContent="center" sx={confirmCardStyles.validationText}>
          Lo sentimos, no hemos podido confirmar tu reserva en este momento. Por favor, ponte en
          contacto con nosotros para continuar con el proceso de confirmación.
        </Typography>
      </Grid>
      <Grid item container paddingBottom={2}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            fontFamily: 'Open Sans, sans-serif',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontSize: '15px',
            width: { xs: '100%' },
            backgroundColor: '#E10600',
          }}
          onClick={() => window.open(salfaClickToCallLink, '_blank', 'noopener noreferrer')}
          fullWidth>
          Contáctanos
        </Button>
      </Grid>
      <Grid item container paddingBottom={3}>
        <Button
          variant="outlined"
          sx={confirmCardStyles.button.outlined}
          fullWidth
          onClick={() => {
            dispatch(resetConfirmState());
            navigate('/');
          }}>
          Ir a inicio
        </Button>
      </Grid>
    </Grid>
  );
}

export default FailedCard;
