const BASE = '[APP]';

const AppTypes = {
  TOGGLE_ERROR_MESSAGE: `${BASE} TOGGLE_ERROR_MESSAGE`,
  TOGGLE_SUCCESS_MESSAGE: `${BASE} TOGGLE_SUCCESS_MESSAGE`,
  GET_STATE_FROM_API: `${BASE} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${BASE} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${BASE} GET_STATE_FROM_API_FAIL`,
  GET_STATE_REPLACE_FROM_API_SUCCESS: `${BASE} GET_STATE_REPLACE_FROM_API_SUCCESS`,
  LOG_OUT: `${BASE} LOG_OUT`,
  SET_ERROR_MESSAGE: `${BASE} SET_ERROR_MESSAGE`,
  SET_SUCCESS_MESSAGE: `${BASE} SET_SUCCESS_MESSAGE`,
  SET_LOADER: `${BASE} SET_LOADER`,
  TOGGLE_CONFIRMATION_DIALOG: `${BASE} TOGGLE_CONFIRMATION_DIALOG`,
  SET_ACTIVE_STEP: `${BASE} SET_ACTIVE_STEP`,
  SET_PREVIOUS_STEP: `${BASE} SET_PREVIOUS_STEP`,
  SET_RESET_STEP: `${BASE} SET_RESET_STEP`,
  SET_VIEW: `${BASE} SET_VIEW`,
  GET_SCHEDULE_STATE_FROM_API: `${BASE} GET_SCHEDULE_STATE_FROM_API`,
  GET_SCHEDULE_STATE_FROM_API_SUCCESS: `${BASE} GET_SCHEDULE_STATE_FROM_API_SUCCESS`,
  GET_ANNULMENT_STATE_FROM_API: `${BASE} GET_ANNULMENT_STATE_FROM_API`,
  GET_ANNULMENT_STATE_FROM_API_SUCCESS: `${BASE} GET_ANNULMENT_STATE_FROM_API_SUCCESS`,
  GO_TO_SCHEDULER_STEP: `${BASE} GO_TO_SCHEDULER_STEP`,
};

export default AppTypes;
