/* eslint-disable no-console */
import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess } from 'utils/api';
import {
  getScheduleStateFromApi,
  getScheduleStateFromApiSuccess,
  getAnnulmentStateFromApi,
  getAnnulmentStateFromApiSuccess,
  getStateFromApi,
  getStateFromApiFail,
  getStateFromApiSuccess,
  setErrorMessage,
} from './app.actions';

const clearStateIfIsUnauthorized = (statusCode) => {
  if (statusCode === 401) {
    localStorage.clear();
    window.location.replace(window.location.origin);
  }
};

const setToken = (token) => {
  if (token) {
    localStorage.setItem('user', JSON.stringify({ token }));
  }
};

const setCsrfToken = (csrfToken) => {
  if (csrfToken) {
    localStorage.setItem('csrfToken', csrfToken);
  }
};

function* reflectGetStateFromApi() {
  // eslint-disable-next-line complexity
  yield takeLatest(getStateFromApi, function* fetchState() {
    try {
      const response = yield apiRequest('api/v1', { method: 'get' });

      clearStateIfIsUnauthorized(response.statusCode);
      setToken(response.token);
      setCsrfToken(response.csrfToken);

      if (response.error) {
        return yield put(
          apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
        );
      }

      return yield put(
        getStateFromApiSuccess({
          user: response.user,
          signedByGoogle: response.signedByGoogle,
          objects: response.objects,
        }),
      );
    } catch (e) {
      // console.log({ e });
      return yield put(getStateFromApiFail());
    }
  });
}

function* getState() {
  yield takeLatest(getScheduleStateFromApi, function* fetchState() {
    const response = yield apiRequest('api/v1/scheduler', { method: 'get' });

    if (response.error) {
      return yield put(
        apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
      );
    }

    return yield put(
      getScheduleStateFromApiSuccess({
        objects: response.objects,
      }),
    );
  });
}

function* getAnnulmentState() {
  yield takeLatest(getAnnulmentStateFromApi, function* fetchAnnulmentState() {
    const response = yield apiRequest('api/v1/annulment', { method: 'get' });

    if (response.error) {
      return yield put(
        apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
      );
    }

    return yield put(
      getAnnulmentStateFromApiSuccess({
        objects: response.objects,
      }),
    );
  });
}

export default function* AppSaga() {
  yield spawn(reflectGetStateFromApi);
  yield spawn(getState);
  yield spawn(getAnnulmentState);
}
