import { createAction } from '@reduxjs/toolkit';

import CounterTypes from 'content/features/counter/counter.types';

const increment = createAction(CounterTypes.increment);

const decrement = createAction(CounterTypes.decrement);

const incrementByAmount = createAction(CounterTypes.incrementByAmount, ({ amount }) => ({
  payload: {
    amount,
  },
}));

const incrementAsync =
  ({ amount }) =>
  (dispatch) => {
    setTimeout(() => {
      dispatch(incrementByAmount({ amount }));
    }, 1000);
  };

const CounterActions = {
  increment,
  decrement,
  incrementByAmount,
  incrementAsync,
};

export default CounterActions;
