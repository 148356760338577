const BASE = '[RECOVERY]';

const RecoveryTypes = {
  POST_VERIFY_EMAIL: `${BASE} POST_VERIFY_EMAIL`,
  POST_VERIFY_EMAIL_SUCCESS: `${BASE} POST_VERIFY_EMAIL_SUCCESS`,
  POST_UPDATE_PASSWORD: `${BASE} POST_UPDATE_PASSWORD`,
  POST_UPDATE_PASSWORD_SUCCESS: `${BASE} POST_UPDATE_PASSWORD_SUCCESS`,
  POST_UPDATE_PASSWORD_ERROR: `${BASE} POST_UPDATE_PASSWORD_ERROR`,
  SEND_RECOVERY_PASSWORD_EMAIL: `${BASE} SEND_RECOVERY_PASSWORD_EMAIL`,
  SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS: `${BASE} SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS`,
  SEND_RECOVERY_PASSWORD_EMAIL_FAIL: `${BASE} SEND_RECOVERY_PASSWORD_EMAIL_FAIL`,
  RECOVER_PASSWORD: `${BASE} RECOVER_PASSWORD`,
  RECOVER_PASSWORD_SUCCESS: `${BASE} RECOVER_PASSWORD_SUCCESS`,
  RECOVER_PASSWORD_FAIL: `${BASE} RECOVER_PASSWORD_FAIL`,
  SET_CONTROLS: `${BASE} SET_CONTROLS`,
  SET_CONTROL: `${BASE} SET_CONTROL`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
  SET_PARAMS: `${BASE} SET_PARAMS`,
  RESET_PARAMS: `${BASE} RESET_PARAMS`,
  DISABLE_ERRORS: `${BASE} DISABLE_ERRORS`,
  RESET_STATE: `${BASE} RESET_STATE`,
  TOGGLE_ERROR: `${BASE} TOGGLE_ERROR`,
  TOGGLE_SUCCESS: `${BASE} TOGGLE_SUCCESS`,
};

export default RecoveryTypes;
