import React, { useMemo, useState } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { FormHelperText, Grid, Paper, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/* Project */
import { onChangeImage, controlsChanged, setControlsErrors } from '../schedulerSteps.actions';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import InformationDialog from './InformationDialog';

const ChevroletLogo = `${window.location.origin}/images/chevrolet-logo.png`;

function BrandsCards() {
  const [openDialog, setopenDialog] = useState(false); // TODO: DELETE STATE AND USE REDUX STORE
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.app.objects.brands);
  const brandExternalId = useSelector((state) => state.schedulerSteps.controls.brandExternalId);
  const brandIdError = useSelector((state) => state.schedulerSteps.controlsErrors.brandId);

  const brandsData = useMemo(() => Object.keys(brands).map((key) => brands[key]), [brands]);
  const brandValidation = (brand) => (brand !== 'Chevrolet' ? 'Debe seleccionar una marca' : '');

  const onChange = (brand) => {
    const errorMessage = brandValidation(brand.name);
    dispatch(
      setControlsErrors({
        controlsErrors: {
          brandId: errorMessage,
        },
      }),
    );
    dispatch(onChangeImage(brand));
  };

  const onClickOthers = () => {
    dispatch(controlsChanged({ name: 'brand', value: 'others' }));
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
    dispatch(controlsChanged({ name: 'brand', value: '' }));
  };

  return (
    <>
      <Grid sx={SchedulerStepsStyles.brandsContainer}>
        {brandsData.map((brand) => (
          <Paper
            key={brand.externalId}
            sx={
              brandExternalId === brand.externalId
                ? SchedulerStepsStyles.brandPaperSelected
                : SchedulerStepsStyles.brandPaper
            }
            onClick={() => onChange(brand)}>
            <Grid>
              <img alt="chevrolet-logo" src={ChevroletLogo} width="80px" height="50px" />
            </Grid>
          </Paper>
        ))}

        <Paper sx={SchedulerStepsStyles.brandPaper} onClick={onClickOthers}>
          <MoreHorizIcon sx={SchedulerStepsStyles.moreIcon} />
          <Typography variant="body2" sx={{ color: '#999' }} gutterBottom>
            Otros
          </Typography>
        </Paper>
      </Grid>
      {!!brandIdError && (
        <FormHelperText sx={{ mt: 2 }} error={!!brandIdError}>
          Debe seleccionar una marca
        </FormHelperText>
      )}
      <InformationDialog open={openDialog} handleClose={handleCloseDialog} type="otherBrand" />
    </>
  );
}

export default BrandsCards;
