import React, { useRef } from 'react';

/* React redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Grid, Typography } from '@mui/material';

/* Project */
import textImageValidationSelectedService from '../../shared/textImageValidationSelectedService';
import SelectService from './components/SelectService';
import StepsActionButtons from './components/StepsActionButtons';

function ServiceForm() {
  const submitButtonRef = useRef(null);

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);
  const { textValidation } = textImageValidationSelectedService[selectedService];

  const handleNext = () => submitButtonRef.current.click();

  return (
    <>
      <Typography color="primary" variant="h6" gutterBottom>
        DETALLE DEL SERVICIO
      </Typography>
      <Grid container direction="row" sx={{ mb: 2 }}>
        <Typography color="primary" variant="subtitle1" gutterBottom>
          Servicio seleccionado:
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          gutterBottom
          sx={{ fontWeight: 'bold', ml: 1 }}>
          {textValidation}
        </Typography>
      </Grid>
      <SelectService submitButtonRef={submitButtonRef} />
      <Grid item xs={12}>
        <StepsActionButtons handleNext={handleNext} />
      </Grid>
    </>
  );
}

export default ServiceForm;
