import { createAction } from '@reduxjs/toolkit';

import RecoveryTypes from './recovery.types';

export const verifyEmail = createAction(RecoveryTypes.RECOVER_PASSWORD);

export const updatePassword = createAction(RecoveryTypes.POST_UPDATE_PASSWORD);

export const updatePasswordSuccess = createAction(RecoveryTypes.POST_UPDATE_PASSWORD_SUCCESS);

export const updatePasswordFail = createAction(RecoveryTypes.POST_UPDATE_PASSWORD_ERROR);

export const setControls = createAction(RecoveryTypes.SET_CONTROLS);

export const setControl = createAction(RecoveryTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));

export const resetControls = createAction(RecoveryTypes.RESET_CONTROLS);

export const setParams = createAction(RecoveryTypes.SET_PARAMS);

export const resetParams = createAction(RecoveryTypes.RESET_PARAMS);

export const disableErrors = createAction(RecoveryTypes.DISABLE_ERRORS);

export const resetState = createAction(RecoveryTypes.RESET_STATE);

export const toggleError = createAction(RecoveryTypes.TOGGLE_ERROR);

export const toggleSuccess = createAction(RecoveryTypes.TOGGLE_SUCCESS);

export const sendRecoverPasswordEmail = createAction(
  RecoveryTypes.SEND_RECOVERY_PASSWORD_EMAIL,
  ({ email, navigate }) => ({
    payload: { email, navigate },
  }),
);

export const sendRecoverPasswordEmailSuccess = createAction(
  RecoveryTypes.SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS,
);

export const sendRecoverPasswordEmailFail = createAction(
  RecoveryTypes.SEND_RECOVERY_PASSWORD_EMAIL_FAIL,
);

const RecoveryActions = {
  verifyEmail,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
  setControl,
  setControls,
  resetControls,
  setParams,
  resetParams,
  disableErrors,
  resetState,
  toggleError,
  toggleSuccess,
  sendRecoverPasswordEmail,
  sendRecoverPasswordEmailSuccess,
  sendRecoverPasswordEmailFail,
};

export default RecoveryActions;
