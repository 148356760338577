import React from 'react';

/* Material UI */
import { Grid, CircularProgress } from '@mui/material';

function Loader(props) {
  const { loading, children } = props;
  return (
    <Grid>
      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '550px' }}>
          <CircularProgress size={70} thickness={5} />
        </Grid>
      ) : (
        children
      )}
    </Grid>
  );
}

export default Loader;
