import React from 'react';

/* React router dom */
import { useLocation } from 'react-router-dom';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import Welcome from 'content/features/welcomeScreens/components/Welcome';

function Home({ title }) {
  const location = useLocation();
  const route = location.pathname;

  return (
    <StyledContainer title={title}>
      <Welcome route={route} />
    </StyledContainer>
  );
}

export default Home;
