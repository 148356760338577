/* React Redux */
import { createReducer } from '@reduxjs/toolkit';
import {
  confirmBooking,
  confirmBookingFailed,
  confirmBookingSuccess,
  getData,
  getDataFailed,
  getDataSuccess,
  resetConfirmState,
  setConfirmData,
} from './confirm.actions';

const initialState = {
  data: '',
  loading: false,
  error: false,
  loadingConfirm: false,
};

const ConfirmReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getData, (state) => {
      state.loading = true;
    })
    .addCase(getDataSuccess, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    })
    .addCase(getDataFailed, (state) => {
      state.loading = false;
      state.error = true;
      state.loadingConfirm = true;
    })
    .addCase(confirmBooking, (state) => {
      state.loading = true;
    })
    .addCase(confirmBookingSuccess, (state) => {
      state.loading = false;
      state.loadingConfirm = true;
    })
    .addCase(confirmBookingFailed, (state) => {
      state.loading = false;
      state.error = true;
      state.loadingConfirm = true;
    })
    .addCase(setConfirmData, (state, action) => {
      state.data = action.payload;
    })
    .addCase(resetConfirmState, () => ({ ...initialState }));
});

export default ConfirmReducer;
