/* Redux Saga */
import { takeLatest, spawn, put, select } from 'redux-saga/effects';

/* Project */
import { setErrorMessage } from 'content/app.actions';
import apiRequest, { apiSuccess } from 'utils/api';
import { getAppointments } from './welcomeScreens.actions';

function* getAppointmentsSaga() {
  yield takeLatest(getAppointments, function* getAppointments(action) {
    const { searchParameter, searchValue, view } = action.payload;

    const socketId = localStorage.getItem('socketId');
    const endpoint = `api/v1/rescheduler/get-appointments?searchParameter=${searchParameter}&searchValue=${searchValue}&view=${view}&socketId=${socketId}`;
    const response = yield apiRequest(endpoint, { method: 'get' });

    return response;
  });
}

export default function* WelcomeScreensSaga() {
  yield spawn(getAppointmentsSaga);
}
