/* eslint-disable complexity */
import React, { useEffect } from 'react';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import { getDocument } from 'content/features/schedulerSteps/schedulerSteps.actions';
import SuccessScreen from 'content/features/schedulerSteps/SuccessScreen';
import moment from 'moment';

function SchedulerVoucher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const view = useSelector((state) => state.app.view);
  const loading = useSelector((state) => state.schedulerSteps.loading);
  const schedulingResponse = useSelector((state) => state.schedulerSteps.schedulingResponse);
  const service = useSelector((state) => state.welcomeScreen.controls.selectedService);

  const { ticketId, date, address, userName, technicianName } = schedulingResponse;

  const successData = {
    id: ticketId,
    date: `${moment(date).format('DD/MM/YYYY')} - ${moment(date).format('HH:mm')} HRS`,
    place: address,
    userName,
    technician: technicianName,
    service,
    type: view,
  };

  const downloadVoucher = () => {
    const selectedDateOffset = moment(date).utcOffset() / 60;
    dispatch(getDocument({ type: view, ticketId, offset: selectedDateOffset }));
  };

  useEffect(() => {
    if (successData.type === '') {
      navigate('/');
    }
  }, [successData]);

  return (
    <StyledContainer title="AGENDAMIENTO TALLER">
      {successData && successData.type !== '' && (
        <SuccessScreen data={successData} downloadVoucher={downloadVoucher} loading={loading} />
      )}
    </StyledContainer>
  );
}

export default SchedulerVoucher;
