import React from 'react';

/* Project */
import NotFoundPage from 'content/pages/NotFound/NotFoundPage';
import ScheduleWelcome from './ScheduleWelcome';
import AnnulmentWelcome from './AnnulmentWelcome';
import RescheduleWelcome from './RescheduleWelcome';

function Welcome(props) {
  const { route } = props;
  // eslint-disable-next-line
  const getWelcomeScreen = () => {
    switch (route) {
      case '/':
        return <ScheduleWelcome />;
      case '/reschedule':
        return <RescheduleWelcome />;
      case '/annulment':
        return <AnnulmentWelcome />;
      default:
        return <NotFoundPage />;
    }
  };

  return <div>{getWelcomeScreen()}</div>;
}

export default Welcome;
