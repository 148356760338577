import React from 'react';
import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';

function NotFoundPage() {
  // <Navigate to="/" />
  const toggleSentryError = process.env.REACT_APP_TOGGLE_SENTRY_ERROR === 'true';
  // console.log('toggleSentryError :', toggleSentryError);
  const testUndefined = useSelector((state) => state.schedulerSteps.controls.technicians);

  return (
    <>
      <div>Page not found</div>
      {toggleSentryError && testUndefined[0].name}
    </>
  );
}

export default NotFoundPage;
