import React, { useCallback } from 'react';

/* Material UI */
import { CardContent, CardMedia, Card, Grid } from '@mui/material';

/* Project */
import RegisterForm from 'content/features/authentication/register/components/RegisterForm';
import { setControl } from 'content/features/authentication/register/register.actions';
import { RegisterSelector } from 'content/features/authentication';

/* React Router */
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/* Public */
const TCITLogo = `${window.location.origin}/images/TcitLogo.png`;

const { page: registerSelector } = RegisterSelector;

function RegisterUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = useSelector(registerSelector);

  const { controls, loading } = states;

  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);

  const goToHome = () => navigate('/');

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card>
            <CardMedia image={TCITLogo} />
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10} textAlign="center">
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      height: 90,
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    image={TCITLogo}
                    title="TCIT"
                  />
                </Grid>
              </Grid>
              <RegisterForm
                navigate={navigate}
                onChangeText={onChangeText}
                controls={controls}
                loading={loading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default RegisterUser;
