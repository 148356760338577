const AnnulmentStepsStyles = {
  title: {
    /* fontSize: (theme) => (theme.breakpoints.down('sm') ? '14px' : '20px'), */
    fontSize: '20px',
    marginBottom: '20px',
  },
  tableHeader: {
    background: '#19255B',
  },
  tableCell: {
    color: '#fff',
  },
  tableValue: {
    color: '#19255B',
  },
  field: {
    marginBottom: '10px',
    '& label.Mui-focused': {
      color: '#001677',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#001677',
      },
      '&:hover fieldset': {
        borderColor: '#19255B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#001677',
      },
      '&::placeholder': {
        color: 'red',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    color: '#19255B',
    textTransform: 'uppercase',
  },
  dialogContent: {
    color: '#19255B',
    padding: '10px 0px 10px 0px',
    textAlign: 'justify',
  },
  dialogIcon: {
    marginRight: 2,
  },
  iconContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  errorIcon: {
    fontSize: 70,
    mb: 2,
    color: '#F50057',
  },
  infoLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  rescheduleParagraph: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  buttonLink: {
    padding: '0px',
    minWidth: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'lowercase',
    marginLeft: '5px',
    marginRight: '5px',
    textDecoration: 'underline',
  },
  disabledText: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
};

export default AnnulmentStepsStyles;
