import React from 'react';

/* Material UI */
import { Typography, Grid, TextField, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import useForm from 'hooks/useForm';
import { validateEmail, validatePassword } from 'utils/functions';
import { createUser } from '../register.actions';

function RegisterForm(props) {
  const { navigate, controls, onChangeText, loading } = props;

  const dispatch = useDispatch();

  const { email, name, password, repeatPassword } = controls;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      name: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Nombre requerido',
        },
      ],
      email: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value).check,
          message: 'Correo electrónico inválido',
        },
      ],
      password: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(password).message}`,
        },
      ],
      repeatPassword: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Confirmación de contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(repeatPassword).message}`,
        },
        {
          check: (value) => value === password,
          message: 'Las contraseñas deben ser iguales',
        },
      ],
    },
  });

  const submitRegisterUser = (e) => {
    e.preventDefault();
    onSubmit(() =>
      dispatch(
        createUser({
          name: controls.name,
          email: controls.email,
          password: controls.password,
          navigate,
        }),
      ),
    );
  };

  return (
    <form onSubmit={submitRegisterUser}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10} textAlign="center">
          <Typography variant="body1" component="div">
            <strong>Registrarse</strong>
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
            /* sx={styles.field} */
            label="Nombre"
            key="name"
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            fullWidth
            error={!!errors.name[0]}
            helperText={errors.name[0]}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            /* sx={styles.field} */
            label="Correo electrónico"
            key="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            fullWidth
            error={!!errors.email[0]}
            helperText={errors.email[0]}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            /* sx={styles.field} */
            label="Contraseña"
            key="password"
            id="password"
            name="password"
            value={password}
            onChange={onChange}
            type="password"
            fullWidth
            error={!!errors.password[0]}
            helperText={errors.password[0]}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            /* sx={styles.field} */
            label="Repetir contraseña"
            key="repeatPassword"
            id="repeatPassword"
            name="repeatPassword"
            value={repeatPassword}
            type="password"
            onChange={onChange}
            fullWidth
            error={!!errors.repeatPassword[0]}
            helperText={errors.repeatPassword[0]}
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justifyContent="space-between"
          alignItems="center"
          /* sx={styles.textAlign} */
        >
          <IconButton edge="start" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '5px' }}
            /* sx={styles.button} */
          >
            registrarse
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default RegisterForm;
