import { createReducer } from '@reduxjs/toolkit';

import {
  changeControls,
  fetchTodoList,
  fetchTodoListSuccess,
  fetchTodoListFail,
  checkTask,
  checkTaskSuccess,
  checkTaskFail,
  createTask,
  createTaskSuccess,
  createTaskFail,
  removeTask,
  removeTaskSuccess,
  removeTaskFail,
  changeActions,
} from './userTasks.actions';

const initialState = {
  loaders: {
    page: false,
    update: false,
    create: false,
  },
  controls: {
    name: 'Tarea ',
    description: '',
  },
  actions: {
    task: null,
  },
  data: {
    tasks: [],
  },
};

const UserTasksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTodoList, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchTodoListSuccess, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchTodoListFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(changeControls, (state, action) => {
      state.controls = {
        ...state.controls,
        ...action.payload,
      };
    })
    .addCase(changeActions, (state, action) => {
      state.actions = {
        ...state.actions,
        ...action.payload,
      };
    })
    .addCase(createTask, (state) => {
      state.loaders.create = true;
    })
    .addCase(createTaskSuccess, (state) => {
      state.loaders.create = false;
      state.controls = initialState.controls;
    })
    .addCase(createTaskFail, (state) => {
      state.loaders.create = false;
    })
    .addCase(checkTask, (state) => {
      state.loaders.update = true;
    })
    .addCase(checkTaskSuccess, (state) => {
      state.loaders.update = false;
    })
    .addCase(checkTaskFail, (state) => {
      state.loaders.update = false;
    })
    .addCase(removeTask, (state) => {
      state.loaders.update = true;
    })
    .addCase(removeTaskSuccess, (state) => {
      state.loaders.update = false;
      state.actions.task = null;
    })
    .addCase(removeTaskFail, (state) => {
      state.loaders.update = false;
    });
});

export default UserTasksReducer;
