/* eslint-disable complexity */
import React, { useCallback, useRef } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Grid, Typography, Switch, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';

/* Project */
import SchedulerStepsStyles from './SchedulerSteps.styles';
import ContactDialog from './components/ContactDialog';
import AddContact from './components/AddContact';
import StepsActionButtons from './components/StepsActionButtons';
import {
  toggleContactDialog,
  toggleAddContact,
  toggleEdit,
  selectContact,
  closeContactDialog,
  controlsChanged,
  clearContact,
  setOpenNotEmailContactDialog,
  updateContact,
  setControlsErrors,
  clearControlsErrors,
} from './schedulerSteps.actions';
import NotEmailContactDialog from './components/NotEmailContactDialog';
import VehicleOwnerForm from './components/VehicleOwnerForm';

function VehicleOwnerStep() {
  const dispatch = useDispatch();
  const selectedContact = useSelector((state) => state.schedulerSteps.controls.selectedContact);
  const selectedIndexContact = useSelector(
    (state) => state.schedulerSteps.controls.selectedIndexContact,
  );
  const contacts = useSelector((state) => state.schedulerSteps.controls.contacts);

  const addContact = useSelector((state) => state.schedulerSteps.addContact);
  const openContactDialog = useSelector((state) => state.schedulerSteps.openContactDialog);
  const isEdit = useSelector((state) => state.schedulerSteps.isEdit);
  const openNotEmailContactDialog = useSelector(
    (state) => state.schedulerSteps.openNotEmailContactDialog,
  );

  const submitButtonRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = () => {
    dispatch(toggleAddContact());
  };

  const toggleCreateDialog = () => {
    dispatch(toggleContactDialog());
  };

  const toggleEditDialog = () => {
    dispatch(toggleEdit());
  };

  const handleClearContact = () => {
    dispatch(clearContact());
  };

  const handleSetOpenNotEmailContactDialog = (newSelectedIndexContact) => {
    dispatch(
      setOpenNotEmailContactDialog({
        openNotEmailContactDialog: !openNotEmailContactDialog,
        selectedIndexContact: newSelectedIndexContact,
      }),
    );
  };

  const handleUpdateContact = ({ newSelectedIndexContact, contactValues }) => {
    dispatch(updateContact({ selectedIndexContact: newSelectedIndexContact, contactValues }));
  };

  const handleChangeTable = (value, index) => {
    if (selectedIndexContact === index) {
      return handleClearContact();
    }

    if (value.email === ' ') {
      handleSetOpenNotEmailContactDialog(index);
    }

    const data = {
      name: value.name,
      lastName: value.lastName,
      email: value.email,
      phone: value.phone,
      isDefault: false,
      externalId: !value.externalId ? 'created_from_site' : value.externalId,
    };

    return dispatch(selectContact({ contact: data, selectedIndexContact: index }));
  };

  const handleChangeContact = (value) => {
    dispatch(selectContact({ contact: value }));
  };

  const handleCloseDialog = () => {
    handleClearContact();
    dispatch(closeContactDialog());
  };

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  /* validation of user contact
  selectedContact: [
      {
        check: (value) => (addContact ? value.toString().trim().length > 0 : true),
        message: 'Debe seleccionar un contacto',
      },
    ],
  */

  const handleNext = () => {
    if (addContact && selectedContact.toString().trim().length < 1) {
      return dispatch(
        setControlsErrors({
          controlsErrors: {
            selectedContact: 'Debe seleccionar un contacto',
          },
        }),
      );
    }

    dispatch(clearControlsErrors());
    return submitButtonRef.current.click();
  };

  return (
    <>
      <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
        DUEÑO DEL VEHÍCULO
      </Typography>

      <VehicleOwnerForm submitButtonRef={submitButtonRef} />
      <Grid id="add-contact">
        <Typography color="primary" variant="body2" gutterBottom>
          ¿Entregará otra persona el vehículo en su nombre?
        </Typography>
        <Grid sx={SchedulerStepsStyles.switchContainer}>
          <Typography>No</Typography>
          <FormControlLabel
            value="bottom"
            control={<Switch color="primary" checked={addContact} onChange={handleChange} />}
            label=""
            labelPlacement="top"
          />
          <Typography>Sí</Typography>
        </Grid>
      </Grid>

      {addContact && (
        <AddContact
          selectContact={selectedContact}
          toggleCreateDialog={toggleCreateDialog}
          toggleEditDialog={toggleEditDialog}
          isMobile={isMobile}
          handleChangeTable={handleChangeTable}
          handleChangeContact={handleChangeContact}
          rows={contacts}
          // errors={errors}
          selectedIndexContact={selectedIndexContact}
        />
      )}
      <ContactDialog
        open={openContactDialog}
        handleClose={handleCloseDialog}
        isEdit={isEdit}
        onChangeText={onChangeText}
        handleUpdateContact={handleUpdateContact}
      />
      <NotEmailContactDialog
        open={openNotEmailContactDialog}
        handleClose={() => handleSetOpenNotEmailContactDialog()}
        handleSetOpenNotEmailContactDialog={handleSetOpenNotEmailContactDialog}
        toggleEditDialog={toggleEditDialog}
        selectedIndexContact={selectedIndexContact}
      />
      <Grid item xs={12}>
        <StepsActionButtons handleNext={handleNext} />
      </Grid>
    </>
  );
}

export default VehicleOwnerStep;
