const pageSelector = (state) => ({
  controls: state.recovery.controls,
  successChangePassword: state.recovery.successChangePassword,
  message: state.recovery.message,
  error: state.recovery.error,
  loading: state.recovery.loading,
});

const RecoverySelector = {
  page: pageSelector,
};

export default RecoverySelector;
