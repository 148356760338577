import { spawn, takeLatest, put } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  sendRecoverPasswordEmail,
  sendRecoverPasswordEmailSuccess,
  sendRecoverPasswordEmailFail,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
} from './recovery.actions';

function* sendResetEmail() {
  yield takeLatest(sendRecoverPasswordEmail, function* sendResetPasswordEmail(action) {
    const { email, navigate } = action.payload;
    const body = JSON.stringify({ email });

    const response = yield apiRequest('api/v1/auth/recover-password', { method: 'post', body });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(sendRecoverPasswordEmailFail());
    } else {
      defaultSuccessToast({ message: 'Correo de recuperación enviado.' });
      navigate('/login');
      yield put(sendRecoverPasswordEmailSuccess());
    }
  });
}

function* generatePassword() {
  yield takeLatest(updatePassword, function* putUserPassword(action) {
    const { email, token, password, navigate } = action.payload;
    const body = JSON.stringify({ email, token, password });

    const response = yield apiRequest('api/v1/auth/reset-password', { method: 'put', body });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(updatePasswordFail());
    } else {
      defaultSuccessToast({ message: 'Contraseña actualizada correctamente.' });
      navigate('/login');
      yield put(updatePasswordSuccess());
    }
  });
}

export default function* RecoverySaga() {
  yield spawn(sendResetEmail);
  yield spawn(generatePassword);
}
