export const tasksSelector = (state) => state.userTasks.data.tasks;

export const loadingSelector = (state) => state.userTasks.loaders.page;

export const updateLoadingSelector = (state) => state.userTasks.loaders.update;

export const creationLoadingSelector = (state) => state.userTasks.loaders.create;

export const controlsSelector = (state) => state.userTasks.controls;

export const actionsTaskSelector = (state) => state.userTasks.actions.task;

const UserTasksSelector = {
  controls: controlsSelector,
  loading: loadingSelector,
  tasks: tasksSelector,
  updateLoading: updateLoadingSelector,
  creationLoading: creationLoadingSelector,
  actionsTask: actionsTaskSelector,
};

export default UserTasksSelector;
