const BASE = '[WelcomeScreen]';

const welcomeScreenTypes = {
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  GET_APPOINTMENTS: `${BASE} GET_APPOINTMENTS`,
  GET_APPOINTMENTS_SUCCESS: `${BASE} GET_APPOINTMENTS_SUCCESS`,
  RESET_WELCOMESCREEN_CONTROLS: `${BASE} RESET_WELCOMESCREEN_CONTROLS`,
  TOGGLE_NO_RECORDS_ERROR: `${BASE} TOGGLE_NO_RECORDS_ERROR`,
  CLOSE_NO_RECORDS_ERROR: `${BASE} CLOSE_NO_RECORDS_ERROR`,
};

export default welcomeScreenTypes;
