import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

function UserTaskListItem(props) {
  const { task, setTaskToRemove, checkTask, loading } = props;

  const { user } = task;

  const labelId = `user-task-item-${task.id}`;
  const labelCreateBy = user && `- creada por: ${user.name}`;

  return (
    <ListItem
      alignItems="center"
      secondaryAction={
        <IconButton
          disabled={loading}
          onClick={() => setTaskToRemove(task)}
          edge="end"
          aria-label="comments">
          <DeleteOutline />
        </IconButton>
      }
      disablePadding>
      <ListItemButton dense>
        <ListItemIcon>
          <Checkbox
            disabled={loading}
            edge="start"
            checked={task.completed}
            tabIndex={-1}
            disableRipple
            onClick={() => checkTask({ ...task, completed: !task.completed })}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          data-testid={labelId}
          id={labelId}
          primary={`${task.name}: ${task.description} ${labelCreateBy}`}
        />
      </ListItemButton>
    </ListItem>
  );
}

UserTaskListItem.propTypes = {
  task: PropTypes.shape({}).isRequired,
  setTaskToRemove: PropTypes.func.isRequired,
  checkTask: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserTaskListItem;
