import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import confirmCardStyles from '../confirmCard.styles';
import ButtonRow from './ButtonRow';

function ConfirmCard(props) {
  const { data, ticketID } = props;
  return (
    <Grid sx={confirmCardStyles.card}>
      <Typography sx={confirmCardStyles.titleCard}>Reserva N° {ticketID}</Typography>
      <Typography sx={confirmCardStyles.subtitleCard}>
        Por favor, confirma tu asistencia o, si es necesario, solicita una nueva fecha o anulación.
      </Typography>
      <Grid container direction="row" justifyContent="space-evenly" paddingTop={2}>
        <Grid item xs={12} md={4} sm={4} container>
          <Grid item container direction="row">
            <AccountCircleIcon sx={{ color: '#E10600' }} />
            <Typography sx={confirmCardStyles.title} paddingLeft={2}>
              Datos generales
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Typography sx={confirmCardStyles.subtitle}>Nombre</Typography>
            <Typography sx={confirmCardStyles.data}>{data.clienteNombre}</Typography>
          </Grid>
          <Grid item container direction="row" spacing={6}>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Servicio</Typography>
              <Typography sx={confirmCardStyles.data}>{data.tipoMantencion}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sm={4} container>
          <Grid
            item
            container
            direction="row"
            sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
            zeroMinWidth>
            <CalendarMonthIcon sx={{ color: '#E10600' }} />
            <Typography sx={confirmCardStyles.title} noWrap paddingLeft={2}>
              Datos de agendamiento
            </Typography>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Fecha</Typography>
              <Typography sx={confirmCardStyles.data}>
                {moment.utc(data.date).local().format('DD-MM-YYYY')}
              </Typography>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Hora</Typography>
              <Typography sx={confirmCardStyles.data}>
                {moment.utc(data.date).local().format('HH:mm')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Región</Typography>
              <Typography sx={confirmCardStyles.data}>{data.descripcionRegion}</Typography>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography sx={confirmCardStyles.subtitle}>Taller</Typography>
              </Grid>
              <Grid item>
                <Typography sx={confirmCardStyles.data}>{data.tallerNombre}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sm={4} container>
          <Grid item container direction="row">
            <DirectionsCarIcon sx={{ color: '#E10600' }} />
            <Typography sx={confirmCardStyles.title} paddingLeft={2}>
              Datos del vehículo
            </Typography>
          </Grid>
          <Grid item container direction="row" spacing={6}>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Marca</Typography>
              <Typography sx={confirmCardStyles.data}>{data.descripcionMarca}</Typography>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Modelo</Typography>
              <Typography sx={confirmCardStyles.data}>{data.descripcionModelo}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={6}>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Versión</Typography>
              <Typography sx={confirmCardStyles.data}>{data.descripcionVersion}</Typography>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Typography sx={confirmCardStyles.subtitle}>Patente</Typography>
              <Typography sx={confirmCardStyles.data}>{data.vehiculoPatente}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <ButtonRow />
      </Grid>
    </Grid>
  );
}

export default ConfirmCard;
