const StyledContainerStyles = {
  paper: {
    marginTop: '20px',
    paddingY: { sm: '28px', xs: '14px' },
    paddingX: { sm: '48px', xs: '10px' },
    marginBottom: '20px',
  },
  container: {
    marginTop: (theme) => (theme.breakpoints.down('sm') ? '0px' : '20px'),
    padding: (theme) => (theme.breakpoints.down('sm') ? '0px 20px 0px 20px' : '10px'),
  },
};

export default StyledContainerStyles;
