const SchedulerStepsStyles = {
  title: {
    /* fontSize: (theme) => (theme.breakpoints.down('sm') ? '14px' : '20px'), */
    fontSize: '20px',
    marginBottom: '20px',
  },
  titleMobile: {
    /* fontSize: (theme) => (theme.breakpoints.down('sm') ? '14px' : '20px'), */
    fontSize: '20px',
    marginBottom: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  patentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: (theme) => (theme.breakpoints.down('sm') ? 'space-between' : 'flex-start'),
    marginTop: '20px',
  },
  rutContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: (theme) => (theme.breakpoints.down('sm') ? 'space-between' : 'flex-start'),
    marginTop: '20px',
    marginBottom: '20px',
  },
  patentField: {
    marginRight: '10px',
    width: '75%',
    '& label.Mui-focused': {
      color: '#001677',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#001677',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#001677',
      },
      '&::placeholder': {
        color: 'red',
      },
    },
  },
  searchButton: {
    width: '20%',
  },
  brandPaper: {
    marginRight: '20px',
    width: '100px',
    height: '100px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandPaperSelected: {
    background: '#CCD4DD',
    marginRight: '20px',
    width: '100px',
    height: '100px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    width: '10%',
  },
  moreIcon: {
    fontSize: '60px',
    color: '#999999',
  },
  field: {
    marginBottom: '15px',
    '& label.Mui-focused': {
      color: '#001677',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#001677',
      },

      '&.Mui-focused fieldset': {
        borderColor: '#001677',
      },
      '&::placeholder': {
        color: 'red',
      },
    },
  },
  fieldVehicleForm: {
    marginBottom: '8px',
    '& label.Mui-focused': {
      color: '#001677',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#001677',
      },

      '&.Mui-focused fieldset': {
        borderColor: '#001677',
      },
      '&::placeholder': {
        color: 'red',
      },
    },
  },
  /*  dialogTitle: {
    textAlign: 'center',
    color: '#19255B',
  },
  dialogContent: {
    color: '#19255B',
  },
  dialogIcon: {
    marginRight: 2,
  },
  iconContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  }, */
  imageBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addContactTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
  },
  datePaper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
  hourContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: '10px',
    height: '45px',
  },
  hourTile: {
    padding: '5px',
    borderRadius: '5px',
    width: '100px',
    height: '45px',
    /* display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer', */

    '&:disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  calendarToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  calendarToolbarLabel: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '5px',
  },
  infoText: {
    marginLeft: '10px',
    color: 'black',
  },
  availableBox: {
    background: '#fff',
    width: '20px',
    height: '20px',
    borderRadius: '5px',
    border: '2px solid #8C8C8C',
  },
  noDateAvailable: {
    background: '#FF6969',
    width: '20px',
    height: '20px',
    borderRadius: '5px',
    border: '2px solid #8C8C8C',
  },
  selectedBox: {
    background: '#fff',
    width: '20px',
    height: '20px',
    borderRadius: '5px',
    border: '2px solid #2196F3',
  },
  unavailableBox: {
    background: '#DEDEDE',
    width: '20px',
    height: '20px',
    borderRadius: '5px',
    border: '2px solid #8C8C8C',
  },
  selectedHourContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resumeContainer: {
    padding: '20px',
  },
  resumeLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  successCard: {
    padding: '40px 20px 40px 20px',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  rescheduleDateContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  successIcon: {
    marginRight: '20px',
    fontSize: '35px',
  },
  successText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#525252',
  },
  rescheduleText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#525252',
    marginRight: '10px',
  },
  sucessActionsContainer: {
    marginTop: '40px',
  },
  sucessActionsButton: {
    marginRight: '5px',
    marginBottom: '20px',
  },
  mobileTableHeader: {
    background: '#19255B',
  },
  tableHeader: {
    background: '#19255B',
  },
  tableCell: {
    color: '#fff',
  },
  tableValue: {
    color: '#19255B',
  },
  infoLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  termsCheckbox: {
    '& .MuiSvgIcon-root': { fontSize: 30 },
    marginLeft: '0px',
    paddingLeft: '0px',
    fontSize: '35px',
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  buttonLink: {
    padding: '0px',
    minWidth: '10px',
    fontWeight: 'bold',
    textTransform: 'lowercase',
    fontSize: '14px',

    textDecoration: 'underline',
  },
};

export default SchedulerStepsStyles;
