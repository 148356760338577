/* Reducers */
import AppReducer from 'content/app.reducer';
import { AnnulmentStepsReducer } from 'content/features/annulmentSteps';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import ConfirmReducer from 'content/features/confirmCard/confirm.reducer';
import { CounterReducer } from 'content/features/counter';
import { SchedulerStepsReducer } from 'content/features/schedulerSteps';
import { UserTasksReducer } from 'content/features/tasks';
import { WelcomeScreenReducer } from 'content/features/welcomeScreens';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  register: RegisterReducer,
  counter: CounterReducer,
  recovery: RecoveryReducer,
  userTasks: UserTasksReducer,
  welcomeScreen: WelcomeScreenReducer,
  schedulerSteps: SchedulerStepsReducer,
  annulmentSteps: AnnulmentStepsReducer,
  confirm: ConfirmReducer,
};

export default MainReducer;
