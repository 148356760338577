import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, Card, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab';

/* React Router */
import { Link } from 'react-router-dom';

/* Project */
import { GoogleButton } from 'content/shared';
import { loginStyles } from 'content/features/authentication';

/* Public */
// import TCITLogo from '/assets/images/TcitLogo.png';
const TCITLogo = `${window.location.origin}/images/TcitLogo.png`;

function LoginForm(props) {
  const { goToHome, controls, errors, onChangeText, handleCallbackResponse, handleLogin, loading } =
    props;

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card elevation={2} style={{ margin: '0px 10px' }}>
            <CardMedia image={TCITLogo} />
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10} textAlign="center">
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      height: 90,
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    image={TCITLogo}
                    title="TCIT"
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                    Inicia sesión
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    autoFocus
                    inputProps={{ 'data-testid': 'text-field-email' }}
                    label="Correo electrónico"
                    id="email"
                    value={controls.email}
                    onChange={onChangeText}
                    style={{ paddingBottom: '8px' }}
                    fullWidth
                    error={!!errors.email.message}
                    helperText={errors.email.message}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    inputProps={{ 'data-testid': 'text-field-password' }}
                    label="Contraseña"
                    onChange={onChangeText}
                    value={controls.password}
                    id="password"
                    fullWidth
                    type="password"
                    error={!!errors.password.message}
                    helperText={errors.password.message}
                  />
                </Grid>
                {controls.error && (
                  <Grid item xs={10} textAlign="center">
                    <Typography style={{ color: 'red' }} align="center" variant="caption">
                      Credenciales inválidas
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={10} textAlign="center">
                  <GoogleButton
                    sx={{ marginTop: '5px', textAlign: '-webkit-center' }}
                    size="large"
                    handleCallbackResponse={handleCallbackResponse}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <LoadingButton
                    data-testid="button-login"
                    onClick={handleLogin}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '5px' }}
                    loadingPosition="start"
                    startIcon={<LockIcon data-testid="auth-spinner" />}
                    loading={loading}>
                    Ingresar
                  </LoadingButton>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Link to="/recovery" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button style={{ width: '90%' }} variant="outlined">
                        Recuperar contraseña
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Link to="/register" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button style={{ width: '90%' }} variant="outlined">
                        Registrarse
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  goToHome: PropTypes.func.isRequired,
  controls: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleCallbackResponse: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
