import React from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/* Project */
import { Link } from '@mui/material';
import { Email, PhoneEnabled } from '@mui/icons-material';
import footerStyles from './footer.styles';

function Footer() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={footerStyles.footer}>
      <Grid alignItems="center">
        <Typography variant="subtitle2">Todos los derechos reservados SALFA</Typography>
      </Grid>
      <Grid alignItems="center" justifyContent="center">
        <Typography variant="subtitle2" sx={footerStyles.infoText}>
          Hablemos
        </Typography>
        <Typography variant="subtitle2" sx={footerStyles.infoText}>
          <PhoneEnabled sx={footerStyles.infoText} /> 600 360 6200
        </Typography>
        <Link
          variant="subtitle2"
          sx={footerStyles.infoText}
          color="white"
          underline="hover"
          target="_blank"
          rel="noopener"
          href="https://www.salfa.cl/centro-de-ayuda/#contactanos">
          <Email sx={footerStyles.infoText} /> Contáctanos
        </Link>
      </Grid>
    </Grid>
  );
}

export default Footer;
