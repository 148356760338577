import { Button, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { confirmBooking } from '../confirm.actions';
import confirmCardStyles from '../confirmCard.styles';

function ButtonRow() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { codigoAgendamientoOnline } = useSelector((state) => state.confirm.data);

  const handleOnClick = () => {
    dispatch(confirmBooking({ codigoAgendamientoOnline }));
  };
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      spacing={{ xs: 2, sm: 6 }}
      sx={confirmCardStyles.buttonRow}
      paddingTop={5}>
      <Grid item>
        <Button
          variant="text"
          sx={confirmCardStyles.button.text}
          onClick={() => navigate('/annulment')}>
          Anular reserva
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={confirmCardStyles.button.outlined}
          onClick={() => navigate('/reschedule')}>
          Reagendar reserva
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" sx={confirmCardStyles.button.contained} onClick={handleOnClick}>
          Confirmar reserva
        </Button>
      </Grid>
    </Grid>
  );
}

export default ButtonRow;
