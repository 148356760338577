import React, { useEffect } from 'react';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import SuccessScreen from 'content/features/schedulerSteps/SuccessScreen';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getDocument } from 'content/features/schedulerSteps/schedulerSteps.actions';
import { useNavigate } from 'react-router-dom';

function ReschedulerVoucher({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schedulingResponse = useSelector((state) => state.schedulerSteps.schedulingResponse);
  const selectedReschedule = useSelector(
    (state) => state.schedulerSteps.controls.selectedReschedule,
  );
  const loading = useSelector((state) => state.schedulerSteps.loading);
  const view = useSelector((state) => state.app.view);
  const { dateWithoutFormat, selectedService } = selectedReschedule;
  // console.log('selectedReschedule :', selectedReschedule);
  const { ticketId, address, userName, technicianName, date } = schedulingResponse;

  const rescheduleData = {
    type: view,
    id: ticketId,
    place: address,
    service: selectedService,
    userName,
    technician: technicianName,
    errorMotive: '',
    rescheduleOldDate: moment(dateWithoutFormat).format('DD/MM/YYYY - HH:mm'),
    rescheduleNewDate: moment(date).format('DD/MM/YYYY - HH:mm'),
  };

  const downloadVoucher = () => {
    dispatch(
      getDocument({
        type: view,
        ticketId,
        oldDate: rescheduleData.rescheduleOldDate,
        newDate: rescheduleData.rescheduleNewDate,
      }),
    );
  };

  useEffect(() => {
    if (view === '') {
      navigate('/');
    }
  }, [rescheduleData]);

  return (
    <StyledContainer title={title}>
      <SuccessScreen data={rescheduleData} loading={loading} downloadVoucher={downloadVoucher} />
    </StyledContainer>
  );
}

export default ReschedulerVoucher;
