import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { CircularProgress, List } from '@mui/material';

/* React Redux */
import { useSelector } from 'react-redux';

/* Project */
import { loadingSelector, updateLoadingSelector } from 'content/features/tasks/userTasks.selector';
import { reselectObjects } from 'utils/helper';
import { objectsSelector } from 'utils/reducer/objects.selector';
import UserTaskListItem from './UserTaskListItem';

function UserTaskList(props) {
  const { setTaskToRemove, handleCheckTask } = props;

  const loading = useSelector(loadingSelector);
  const updateLoading = useSelector(updateLoadingSelector);
  const objectsState = useSelector(objectsSelector);

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);
  const objectTasks = Object.values(objects.tasks);

  const loader = <CircularProgress disableShrink />;

  return (
    <List sx={{ width: '100%' }}>
      {loading
        ? loader
        : objectTasks.map((task) => (
            <UserTaskListItem
              key={task.id}
              task={task}
              checkTask={handleCheckTask}
              setTaskToRemove={setTaskToRemove}
              loading={updateLoading}
            />
          ))}
    </List>
  );
}

UserTaskList.propTypes = {
  setTaskToRemove: PropTypes.func.isRequired,
  handleCheckTask: PropTypes.func.isRequired,
};

export default UserTaskList;
