import camelize from 'camelcase-keys-deep';

export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  get tasks() {
    let response = null;
    if (this.id) {
      response = this.env().Task.findAllBy('userId', this.id);
    }
    return response;
  }
}
