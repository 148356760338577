import React, { useEffect } from 'react';

/* Material UI */
import { Grid, Typography } from '@mui/material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import { UserTaskForm, UserTaskList } from 'content/features/tasks';
import { changeActions, checkTask, fetchTodoList } from 'content/features/tasks/userTasks.actions';
import UserTaskDelete from 'content/features/tasks/components/UserTaskDelete';

function UserTasks() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTodoList());
  }, []);

  const handleCheckTask = (task) => {
    dispatch(checkTask({ task }));
  };

  const setTaskToRemove = (task) => {
    dispatch(changeActions({ task }));
  };

  return (
    <Grid container spacing={3} alignItems="center" textAlign="center">
      <Grid item xs={12}>
        <UserTaskForm />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <strong>Lista de Tareas</strong>
        </Typography>
        <UserTaskList handleCheckTask={handleCheckTask} setTaskToRemove={setTaskToRemove} />
      </Grid>
      <UserTaskDelete setTaskToRemove={setTaskToRemove} />
    </Grid>
  );
}

export default UserTasks;
