import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest from 'utils/api';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchTodoList,
  fetchTodoListSuccess,
  fetchTodoListFail,
  createTask,
  createTaskSuccess,
  createTaskFail,
  checkTask,
  checkTaskSuccess,
  checkTaskFail,
  removeTask,
  removeTaskSuccess,
  removeTaskFail,
} from './userTasks.actions';

function* fetchTasks() {
  yield takeLatest(fetchTodoList, function* fetchTasksToApi() {
    const response = yield apiRequest('api/v1/tasks', { method: 'get' });

    if (!response.error) {
      yield put(fetchTodoListSuccess({ objects: response.objects }));
    } else {
      yield put(fetchTodoListFail());
      defaultErrorToast({ message: 'Falló la carga de tareas.' });
    }
  });
}

function* reflectCreateTask() {
  yield takeLatest(createTask, function* createTaskInApi(action) {
    const body = JSON.stringify(action.payload);

    const response = yield apiRequest('api/v1/tasks', { method: 'post', body });

    if (!response.error) {
      yield put(createTaskSuccess({ objects: response.objects }));
      defaultSuccessToast({ message: 'Tarea creada correctamente.' });
    } else {
      yield put(createTaskFail());
      defaultErrorToast({ message: `Falló la actualización de tareas: ${response.message}` });
    }
  });
}

function* updateTask() {
  yield takeLatest(checkTask, function* updateTaskInApi(action) {
    const body = JSON.stringify(action.payload.task);

    const response = yield apiRequest(`api/v1/tasks/${action.payload.task.id}`, {
      method: 'put',
      body,
    });

    if (response.error) {
      defaultErrorToast({ message: `Falló la actualización de tareas: ${response.message}` });
      yield put(checkTaskFail());
    } else {
      defaultSuccessToast({ message: 'Tarea actualizada correctamente.' });
      yield put(checkTaskSuccess({ objects: response.objects }));
    }
  });
}

function* deleteTask() {
  yield takeLatest(removeTask, function* deleteTaskInApi(action) {
    const body = JSON.stringify(action.payload.task);

    const response = yield apiRequest(`api/v1/tasks/${action.payload.task.id}`, {
      method: 'delete',
      body,
    });

    if (response.error) {
      defaultErrorToast({ message: `Falló la eliminación de tarea: ${response.message}.` });
      yield put(removeTaskFail());
    } else {
      defaultSuccessToast({ message: 'Tarea eliminada correctamente.' });
      yield put(removeTaskSuccess({ ids: response.ids, objects: response.objects }));
    }
  });
}

export default function* UserTasksSaga() {
  yield spawn(fetchTasks);
  yield spawn(reflectCreateTask);
  yield spawn(updateTask);
  yield spawn(deleteTask);
}
