import { createAction } from '@reduxjs/toolkit';

import UserTasksTypes from './userTasks.types';

export const fetchTodoList = createAction(UserTasksTypes.FETCH_TASKS);

export const fetchTodoListSuccess = createAction(UserTasksTypes.FETCH_TASKS_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const fetchTodoListFail = createAction(UserTasksTypes.FETCH_TASKS_FAIL);

export const changeControls = createAction(UserTasksTypes.CHANGE_CONTROLS, ({ name, value }) => ({
  payload: {
    [name]: value,
  },
}));

export const changeActions = createAction(UserTasksTypes.CHANGE_ACTIONS);

export const checkTask = createAction(UserTasksTypes.CHECK_TASK);

export const checkTaskSuccess = createAction(UserTasksTypes.CHECK_TASK_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const checkTaskFail = createAction(UserTasksTypes.CHECK_TASK_FAIL);

export const removeTask = createAction(UserTasksTypes.DELETE_TASK);

export const removeTaskSuccess = createAction(UserTasksTypes.DELETE_TASK_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const removeTaskFail = createAction(UserTasksTypes.DELETE_TASK_FAIL);

export const createTask = createAction(UserTasksTypes.CREATE_TASK, (payload) => ({
  payload: {
    ...payload,
    completed: false,
  },
}));

export const createTaskSuccess = createAction(UserTasksTypes.CREATE_TASK_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const createTaskFail = createAction(UserTasksTypes.CREATE_TASK_FAIL);

const UserTasksActions = {
  fetchTodoList,
  fetchTodoListSuccess,
  fetchTodoListFail,

  changeControls,
  changeActions,

  checkTask,
  checkTaskSuccess,
  checkTaskFail,

  removeTask,
  removeTaskSuccess,
  removeTaskFail,

  createTask,
  createTaskSuccess,
  createTaskFail,
};

export default UserTasksActions;
