/* Redux */
import { createAction } from '@reduxjs/toolkit';

/* Project */
import WelcomeScreenTypes from './welcomeScreens.types';

export const controlsChanged = createAction(
  WelcomeScreenTypes.CONTROLS_CHANGED,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const getAppointments = createAction(WelcomeScreenTypes.GET_APPOINTMENTS, (payload) => ({
  payload,
}));

export const getAppointmentsSuccess = createAction(
  WelcomeScreenTypes.GET_APPOINTMENTS_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const resetWelcomeScreenControls = createAction(
  WelcomeScreenTypes.RESET_WELCOMESCREEN_CONTROLS,
);

export const toggleNoRecordsError = createAction(WelcomeScreenTypes.TOGGLE_NO_RECORDS_ERROR);
export const closeNoRecordsError = createAction(WelcomeScreenTypes.CLOSE_NO_RECORDS_ERROR);

const WelcomeScreenActions = {
  controlsChanged,
  getAppointments,
  getAppointmentsSuccess,
  resetWelcomeScreenControls,
  toggleNoRecordsError,
  closeNoRecordsError,
};

export default WelcomeScreenActions;
