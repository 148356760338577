/**
 * @type {import('@mui/material').ThemeOptions}
 */

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    primary: {
      main: '#002855',
    },
  },
};

export default defaultTheme;
