import React, { useCallback, useEffect, useRef } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

/* Project */
import useForm from 'hooks/useForm';
import { formatRutNew, validatePatent, validateRut } from 'utils/functions';
import { getAnnulmentStateFromApi, setView } from 'content/app.actions';
import ErrorDialog from 'content/features/dialogs/ErrorDialog';
import LoaderDialog from 'content/features/loaders/LoaderDialog';
import { darkerGreyOnForms } from 'utils/helper';
import { closeNoRecordsError, controlsChanged, getAppointments } from '../welcomeScreens.actions';
import homeStyles from '../home.styles';

function AnnulmentWelcome() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const controls = useSelector((state) => state.welcomeScreen.controls);
  const view = useSelector((state) => state.app.view);
  const loading = useSelector((state) => state.welcomeScreen.loading);
  const error = useSelector((state) => state.welcomeScreen.error);
  const errorType = useSelector((state) => state.welcomeScreen.errorType);

  useEffect(() => {
    dispatch(setView({ value: 'annulment' }));
    dispatch(getAnnulmentStateFromApi());
  }, []);
  const radioRef = useRef(null);

  const onChangeText = useCallback(
    (id, value) => {
      let newValue = value;
      if (radioRef.current === 'rut' && id === 'annulmentSearchValue') {
        newValue = formatRutNew(value);
      }
      dispatch(controlsChanged({ name: id, value: newValue }));
    },
    [radioRef],
  );

  const { onChange, onSubmit, errors, validate } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      annulmentParameter: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Debe seleccionar una opción ',
        },
      ],
      annulmentSearchValue: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un valor',
        },
        {
          check: (value) => (radioRef.current === 'rut' ? validateRut(value) : true),
          message: 'RUT inválido',
        },
        {
          check: (value) => (radioRef.current === 'patent' ? validatePatent(value) : true),
          message: 'Patente inválida',
        },
      ],
    },
  });

  const onChangeRadio = useCallback(
    ({ target }) => {
      radioRef.current = target.value;
      const event = { type: 'click', target: { name: 'annulmentParameter', value: target.value } };
      onChange(event);
      if (controls.annulmentParameter !== '') {
        validate('annulmentSearchValue', controls.annulmentSearchValue);
      }
    },
    [onChange],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const { annulmentParameter, annulmentSearchValue } = controls;
    onSubmit(() =>
      dispatch(
        getAppointments({
          searchValue: annulmentSearchValue,
          searchParameter: annulmentParameter,
          view,
        }),
      ),
    );
  };

  const limitRutField = radioRef.current === 'rut' ? { maxLength: 10 } : {};

  return (
    <Grid>
      <Grid>
        <Typography color="primary">Seleccione uno de los siguientes datos</Typography>
      </Grid>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid sx={homeStyles.container}>
          <FormControl>
            <RadioGroup
              row={!isMobile}
              id="annulmentParameter"
              name="annulmentParameter"
              value={controls.annulmentParameter}
              onChange={onChangeRadio}>
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="rut"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">RUT</Typography>}
              />
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="patent"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">Patente</Typography>}
              />
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="idTicket"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">ID Ticket</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <FormHelperText error={!!errors.annulmentParameter[0]}>
            {errors.annulmentParameter[0]}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} sm={12} md={4} sx={homeStyles.container}>
          <TextField
            color="primary"
            label="Ingresar RUT, patente o ID Ticket"
            InputLabelProps={{
              sx: { ...darkerGreyOnForms },
            }}
            variant="outlined"
            fullWidth
            inputProps={{ ...limitRutField }}
            sx={homeStyles.field}
            id="annulmentSearchValue"
            name="annulmentSearchValue"
            key="annulmentSearchValue"
            value={controls.annulmentSearchValue}
            onChange={onChange}
            error={!!errors.annulmentSearchValue[0]}
            helperText={errors.annulmentSearchValue[0]}
          />
        </Grid>
      </form>
      <Grid item xs={12} sx={homeStyles.actionsContainer}>
        <Button
          variant="contained"
          fullWidth={isMobile}
          onClick={(e) => handleSubmit(e)}
          disabled={loading}
          startIcon={
            loading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
          }>
          {loading ? 'Cargando' : 'Buscar'}
        </Button>
      </Grid>
      <ErrorDialog
        open={error}
        view="annulment"
        errorType={errorType}
        handleClose={() => dispatch(closeNoRecordsError())}
      />
      <LoaderDialog open={loading} title="Buscando agendamientos" />
    </Grid>
  );
}

export default AnnulmentWelcome;
