import React, { useEffect } from 'react';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import ConfirmBooking from 'content/features/confirmCard/ConfirmBooking';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

function Confirm({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketID } = useParams();
  const { data, loading, loadingConfirm } = useSelector((state) => state.confirm);

  useEffect(() => {
    dispatch(resetAnnulmentState());
  }, []);

  useEffect(() => {
    if (loadingConfirm) {
      navigate('/confirm/validation');
    }
  }, [loadingConfirm]);

  return (
    <StyledContainer title={title}>
      <ConfirmBooking data={data} loading={loading} ticketID={ticketID} />
    </StyledContainer>
  );
}

export default Confirm;
