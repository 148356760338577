import { spawn, takeLatest } from 'redux-saga/effects';

/* Project */
import apiRequest, { controlledCall, getDocumentApi } from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import { cancelAppointment, getAnnulmentDocument } from './annulmentSteps.actions';
import AnnulmentStepsTypes from './annulmentSteps.types';

function* cancelScheduling() {
  yield takeLatest(cancelAppointment, function* cancelSchedulingFunc(action) {
    const socketId = localStorage.getItem('socketId');

    const payloadData = { ...action.payload, socketId };
    const body = JSON.stringify(payloadData);
    yield apiRequest('api/v1/annulment/cancel-scheduling', {
      method: 'post',
      body,
    });
    // console.log({
    //   response,
    // });
  });
}

function* getAnnulmentVoucherDocument() {
  yield takeLatest(getAnnulmentDocument, function* getAnnulmentVoucher(action) {
    const successFunction = (payload) => ({
      type: AnnulmentStepsTypes.GET_VOUCHER_DOCUMENT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: AnnulmentStepsTypes.GET_VOUCHER_DOCUMENT_FAIL,
      payload,
    });
    const { ticketId, annulmentMotive, offset } = action.payload;

    const response = yield controlledCall(
      getDocumentApi(`TicketAnulacion-${ticketId}`, 'pdf', true),
      `api/v1/scheduler/voucher?type=annulment&ticketId=${ticketId}&annulmentMotive=${annulmentMotive}&offset=${offset}`,
      null,
      successFunction,
      failFunction,
    );

    if (response.error) {
      defaultErrorToast({ message: response.message });
    }
  });
}

export default function* AnnulmentSaga() {
  yield spawn(cancelScheduling);
  yield spawn(getAnnulmentVoucherDocument);
}
