import React, { useCallback } from 'react';

/* Material UI */
import { Grid } from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Project */
import useForm from 'hooks/useForm';
import ResultsTable from './components/ResultsTable';
import CancellationReason from './components/CancellationReason';
import {
  cancelAppointment,
  clearErrorDialog,
  controlsChanged,
  selectAnnulment,
} from './annulmentSteps.actions';
import ErrorDialog from '../dialogs/ErrorDialog';
import LoaderDialog from '../loaders/LoaderDialog';

function CancelAppointment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controls = useSelector((state) => state.annulmentSteps.controls);
  const loading = useSelector((state) => state.annulmentSteps.loading);
  const error = useSelector((state) => state.annulmentSteps.error);

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      annulmentMotive: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Debe seleccionar una opción ',
        },
      ],
      selectedAnnulments: [
        {
          check: (value) => Object.keys(value).length > 0,
          message: 'Debe seleccionar un agendamiento para anular',
        },
      ],
    },
  });

  const handleChangeTable = (value) => {
    dispatch(selectAnnulment(value));
  };

  const submitAnnulment = () => {
    onSubmit(() =>
      dispatch(
        cancelAppointment({
          schedulingCode: controls.selectedAnnulments?.onlineCode,
          annulmentMotiveId: controls.annulmentMotive,
        }),
      ),
    );
  };

  return (
    <Grid>
      <form onSubmit={submitAnnulment}>
        <Grid sx={{ marginBottom: '20px' }}>
          <ResultsTable onChange={handleChangeTable} errors={errors} />
        </Grid>
        <Grid sx={{ marginBottom: '20px' }}>
          <CancellationReason
            onChange={onChange}
            onSubmit={submitAnnulment}
            controls={controls}
            errors={errors}
            loading={loading}
          />
        </Grid>
      </form>
      <ErrorDialog
        open={error}
        view="annulment"
        errorType="server-error"
        handleClose={() => dispatch(clearErrorDialog())}
      />
      <LoaderDialog open={loading} title="Anulando agendamiento" />
    </Grid>
  );
}

export default CancelAppointment;
