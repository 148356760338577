import React from 'react';
import PropTypes from 'prop-types';

/* React Redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Button } from '@mui/material';

function ScheduleWelcomeButton(props) {
  const { isMobile, handleSubmit } = props;

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);

  return (
    <Button
      disabled={selectedService === ''}
      variant="contained"
      fullWidth={isMobile}
      sx={isMobile ? { mt: 6, mb: 1 } : { mt: 3 }}
      onClick={handleSubmit}>
      AGENDAR
    </Button>
  );
}

ScheduleWelcomeButton.propTypes = {
  isMobile: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

ScheduleWelcomeButton.defaultProps = {
  isMobile: false,
  handleSubmit: () => {},
};

export default ScheduleWelcomeButton;
