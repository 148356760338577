import camelize from 'camelcase-keys-deep';

export default class Task {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  get user() {
    let response = null;
    if (this.id) {
      response = this.env().User.findBy('id', this.userId);
    }
    return response;
  }
}
