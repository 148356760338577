/* Redux toolkit */
import { combineReducers, configureStore } from '@reduxjs/toolkit';

/* Redux Persist */
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

/* Project */
import MainReducer from '../store/main.reducer';
import MainSaga from '../store/main.saga';
import middlewares, { sagaMiddleware } from './middleware';

const allReducers = combineReducers(MainReducer);

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    const newInitialState = {};
    Object.keys(MainReducer).forEach((reducerKey) => {
      newInitialState[reducerKey] = MainReducer[reducerKey].getInitialState();
    });

    return allReducers(newInitialState, action);
  }

  return allReducers(state, action);
};

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// TODO: Find other solution for navigate in saga
const defaultStore = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  preloadedState: {},
});

sagaMiddleware.run(MainSaga);

export default defaultStore;
