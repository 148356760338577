const availableHours = [
  { hour: '08:30', available: true, validatePrev: false, showByDefault: true },
  { hour: '10:00', available: true, validatePrev: false, showByDefault: true },
  { hour: '10:30', available: true, validatePrev: false, showByDefault: false },
  { hour: '11:00', available: true, validatePrev: false, showByDefault: true },
  { hour: '11:30', available: true, validatePrev: false, showByDefault: false },
  { hour: '14:30', available: true, validatePrev: false, showByDefault: true },
  { hour: '15:30', available: true, validatePrev: false, showByDefault: true },
  { hour: '16:00', available: true, validatePrev: false, showByDefault: true },
  { hour: '16:30', available: true, validatePrev: false, showByDefault: true },
  { hour: '17:00', available: true, validatePrev: false, showByDefault: false },
];

export default availableHours;
