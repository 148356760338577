import React from 'react';

/* Material UI */

/* React Redux */

/* React Router */

/* Project */
import FailedCard from 'content/features/confirmCard/components/FailedCard';
import ValidationCard from 'content/features/confirmCard/components/ValidationCard';
import ValidationStyledContainer from 'content/features/confirmCard/components/ValidationStyledContainer';
import { useSelector } from 'react-redux';

function ConfirmValidation({ title }) {
  const error = useSelector((state) => state.confirm.error);

  return (
    <ValidationStyledContainer title={title}>
      {error ? <FailedCard /> : <ValidationCard />}
    </ValidationStyledContainer>
  );
}

export default ConfirmValidation;
