const BASE = '[ConfirmBooking]';

const ConfirmTypes = {
  GET_DATA: `${BASE} GET_DATA`,
  GET_DATA_SUCCESS: `${BASE} GET_DATA_SUCCESS`,
  GET_DATA_FAILED: `${BASE} GET_DATA_FAILED`,
  CONFIRM_BOOKING: `${BASE} CONFIRM_BOOKING`,
  CONFIRM_BOOKING_SUCCESS: `${BASE} CONFIRM_BOOKING_SUCCESS`,
  CONFIRM_BOOKING_FAILED: `${BASE} CONFIRM_BOOKING_FAILED`,
  RESET_CONFIRM_STATE: `${BASE} RESET_CONFIRM_STATE`,
  SET_CONFIRM_DATA: `${BASE} SET_CONFIRM_DATA`,
};

export default ConfirmTypes;
