import React from 'react';

/* React redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Button } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

function ContactButtons(props) {
  const { toggleCreateDialog } = props;
  const disableCreateContact = useSelector(
    (state) => state.schedulerSteps.controls.disableCreateContact,
  );

  return (
    <Button
      variant="contained"
      size="small"
      sx={{ height: '30px' }}
      onClick={toggleCreateDialog}
      disabled={disableCreateContact}>
      <PersonAddAlt1Icon />
    </Button>
  );
}

export default ContactButtons;
