import * as React from 'react';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function InformationDialog(props) {
  const { open, handleClose } = props;
  const salfaClickToCallLink = process.env.REACT_APP_LINK_CLICK_TO_CALL;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={SchedulerStepsStyles.dialogTitle}>
          Estimado cliente:
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={SchedulerStepsStyles.dialogContent}>
            En estos momentos no es posible autogestionar el agendamiento, favor contactar a nuestro
            contact center.
          </DialogContentText>
          <Grid
            sx={SchedulerStepsStyles.iconContent}
            display="flex"
            alignItems="center"
            marginTop="20px">
            <Button
              color="primary"
              variant="contained"
              onClick={() => window.open(salfaClickToCallLink, '_blank', 'noopener noreferrer')}>
              Contactar ejecutivo
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InformationDialog;
