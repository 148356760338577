import { createAction } from '@reduxjs/toolkit';

import AppTypes from './app.types';

export const getStateFromApi = createAction(AppTypes.GET_STATE_FROM_API);

export const getStateFromApiSuccess = createAction(AppTypes.GET_STATE_FROM_API_SUCCESS);

export const getStateFromApiFail = createAction(AppTypes.GET_STATE_FROM_API_FAIL);

export const getStateReplaceFromApiSuccess = createAction(
  AppTypes.GET_STATE_REPLACE_FROM_API_SUCCESS,
);

export const toggleErrorMessage = createAction(AppTypes.TOGGLE_ERROR_MESSAGE);

export const setErrorMessage = createAction(AppTypes.SET_ERROR_MESSAGE);

export const toggleSuccessMessage = createAction(AppTypes.TOGGLE_SUCCESS_MESSAGE);

export const setSuccessMessage = createAction(AppTypes.SET_SUCCESS_MESSAGE);

export const toggleConfirmationDialog = createAction(AppTypes.TOGGLE_CONFIRMATION_DIALOG);

export const setActiveStep = createAction(AppTypes.SET_ACTIVE_STEP);
export const setPreviousStep = createAction(AppTypes.SET_PREVIOUS_STEP);
export const setResetSteps = createAction(AppTypes.SET_RESET_STEP);
export const goToSchedulerStep = createAction(AppTypes.GO_TO_SCHEDULER_STEP, (payload) => ({
  payload,
}));

export const setView = createAction(AppTypes.SET_VIEW, ({ value }) => ({
  payload: {
    view: value,
  },
}));

export const getScheduleStateFromApi = createAction(AppTypes.GET_SCHEDULE_STATE_FROM_API);

export const getScheduleStateFromApiSuccess = createAction(
  AppTypes.GET_SCHEDULE_STATE_FROM_API_SUCCESS,
);

export const getAnnulmentStateFromApi = createAction(AppTypes.GET_ANNULMENT_STATE_FROM_API);

export const getAnnulmentStateFromApiSuccess = createAction(
  AppTypes.GET_ANNULMENT_STATE_FROM_API_SUCCESS,
);

const AppActions = {
  getStateFromApi,
  getStateFromApiSuccess,
  getStateReplaceFromApiSuccess,
  toggleErrorMessage,
  setErrorMessage,
  toggleSuccessMessage,
  setSuccessMessage,
  toggleConfirmationDialog,
  setView,
  getScheduleStateFromApi,
  getScheduleStateFromApiSuccess,
  getAnnulmentStateFromApi,
  getAnnulmentStateFromApiSuccess,
  goToSchedulerStep,
};

export default AppActions;
