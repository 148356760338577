// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
// import environments from './environments';

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// if (process.env.NODE_ENV !== 'production' && environments.CONSOLE_LOGGER === 'true') {
//   middlewares.push(createLogger());
// }

export default middlewares;
