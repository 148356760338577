const BASE = '[AnnulmentSteps]';

const AnnulmentStepsTypes = {
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  ADD_USER_CONTACT: `${BASE} ADD_USER_CONTACT`,
  SELECT_ANNULMENT: `${BASE} SELECT_ANNULMENT`,
  OPEN_APPOINTMENT_INFO: `${BASE} OPEN_APPOINTMENT_INFO`,
  CLOSE_APPOINTMENT_INFO: `${BASE} CLOSE_APPOINTMENT_INFO`,
  CANCEL_APPOINTMENT: `${BASE} CANCEL_APPOINTMENT`,
  CANCEL_APPOINTMENT_SUCCESS: `${BASE} CANCEL_APPOINTMENT_SUCCESS`,
  RESET_STATE: `${BASE} RESET_STATE`,
  GET_VOUCHER_DOCUMENT: `${BASE} GET_VOUCHER_DOCUMENT`,
  GET_VOUCHER_DOCUMENT_SUCCESS: `${BASE} GET_VOUCHER_DOCUMENT_SUCCESS`,
  GET_VOUCHER_DOCUMENT_FAIL: `${BASE} GET_VOUCHER_DOCUMENT_FAIL`,
  TOGGLE_ERROR_DIALOG: `${BASE} TOGGLE_ERROR_DIALOG`,
  CLEAR_ERROR_DIALOG: `${BASE} CLEAR_ERROR_DIALOG`,
};

export default AnnulmentStepsTypes;
