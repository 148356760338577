import { put, select, spawn, takeLatest } from 'redux-saga/effects';

/* Project */
import { setErrorMessage } from 'content/app.actions';
import apiRequest, { apiSuccess, controlledCall, getDocumentApi } from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  getAvailabilities,
  getDates,
  getDatesSuccess,
  getDocument,
  getDocumentFail,
  getDocumentSuccess,
  getTechniciansAndAvailabilities,
  getUserData,
  getUserVehicle,
  getUserVehicleSuccess,
  getUserVehicleWithConectaApi,
  resetIsFindedVehicle,
  submitReSchedule,
  submitSchedule,
  toggleSchedulerError,
} from './schedulerSteps.actions';
import SchedulerStepsTypes from './schedulerSteps.types';

function* resetIsFindedVehicleGenerator() {
  const isFindedVehicle = yield select((store) => store.schedulerSteps.isFindedVehicle);

  if (isFindedVehicle) {
    yield put(resetIsFindedVehicle({ isFindedVehicle: !isFindedVehicle }));
  }
}

function* getScheduleVoucherDocument() {
  yield takeLatest(getDocument, function* getScheduleVoucher(action) {
    /* const response = yield api('api/v1/scheduler/voucher', { method: 'get' }); */

    const successFunction = (payload) => ({
      type: SchedulerStepsTypes.GET_VOUCHER_DOCUMENT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: SchedulerStepsTypes.GET_VOUCHER_DOCUMENT_FAIL,
      payload,
    });

    const { type, ticketId, newDate, oldDate, offset } = action.payload;

    let title;
    if (type === 'schedule') {
      title = 'TicketAgendamiento';
    } else if (type === 'reschedule') {
      title = 'TicketReAgendamiento';
    } else {
      title = 'TicketAnulacion';
    }

    const response = yield controlledCall(
      getDocumentApi(`${title}-${ticketId}`, 'pdf', true),
      `api/v1/scheduler/voucher?type=${type}&ticketId=${ticketId}&newDate=${newDate}&oldDate=${oldDate}&offset=${offset}`,
      null,
      successFunction,
      failFunction,
    );

    if (!response.error) {
      yield put(getDocumentSuccess());
    } else {
      yield put(getDocumentFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

// agregar variable de entorno para cambiar endpoit en el cual se llama la integración
function* getUserVehicleData() {
  yield takeLatest(getUserVehicle, function* getUserVehicleDataFunc(action) {
    const { patent } = action.payload;

    const response = yield apiRequest(`api/v1/scheduler/uservehicle/${patent}`, { method: 'get' });

    if (response.error) {
      const { errorMsg } = response;
      yield resetIsFindedVehicleGenerator();

      return yield put(toggleSchedulerError({ errorMsg }));
    }

    const { userVehicle, client, contacts } = response;

    return yield put(getUserVehicleSuccess({ userVehicle, client, contacts }));
  });
}

// ### socket logic
function* getUserVehicleDataWithConectaApiSaga() {
  yield takeLatest(getUserVehicleWithConectaApi, function* getUserVehicleDataFunc(action) {
    const { patent } = action.payload;
    const socketId = localStorage.getItem('socketId');

    const response = yield apiRequest(
      `api/v1/scheduler/search_by_license_plate?patent=${patent}&socketId=${socketId}`,
      { method: 'get' },
    );

    if (response.error) {
      const { errorMsg } = response;
      yield resetIsFindedVehicleGenerator();

      return yield put(toggleSchedulerError({ errorMsg }));
    }

    return response;
  });
}

function* getUserInfo() {
  yield takeLatest(getUserData, function* getUserDataFunc(action) {
    const { rut } = action.payload;
    const socketId = localStorage.getItem('socketId');
    const response = yield apiRequest(
      `api/v1/scheduler/user_by_rut?rut=${rut}&socketId=${socketId}`,
      { method: 'get' },
    );

    if (response.error) {
      return yield put(
        apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
      );
    }
    return response;
  });
}

function* getDatesData() {
  yield takeLatest(getDates, function* getDatesFunc() {
    const response = yield apiRequest('api/v1/scheduler/dates', { method: 'get' });

    if (response.error) {
      return yield put(apiSuccess(setErrorMessage, { msg: 'Falló la carga.' }));
    }

    return yield put(getDatesSuccess(response));
  });
}

// TODO: end logic of this saga
function* submitScheduleData() {
  yield takeLatest(submitSchedule, function* getDatesFunc(action) {
    // const isFindedVehicle = yield select((store) => store.schedulerSteps.isFindedVehicle);
    const socketId = localStorage.getItem('socketId');

    const { controls, selectedService, addContact } = action.payload;
    const payloadData = { controls, selectedService, addContact, socketId };
    const body = JSON.stringify(payloadData);
    yield apiRequest('api/v1/scheduler/submit', { method: 'post', body });
    // console.log({
    //   response,
    // });

    // if (!response.error) {
    //   navigate('/schedule/success');
    // } else {
    //   return yield put(apiSuccess(setErrorMessage, { msg: 'Falló la carga.' }));
    // }

    // return yield put(submitScheduleSuccess(response));
  });
}
function* submitReScheduleData() {
  yield takeLatest(submitReSchedule, function* submitRescheduleFunc(action) {
    const socketId = localStorage.getItem('socketId');

    const payloadData = { ...action.payload, socketId };
    const body = JSON.stringify(payloadData);
    yield apiRequest('api/v1/scheduler/reschedule/submit', {
      method: 'post',
      body,
    });
    // console.log({
    //   response,
    // });
  });
}

/* function* submitReScheduleData() {
  yield takeLatest(submitReSchedule, function* submitRescheduler(action) {
    const { controls, navigate } = action.payload;
    const payloadData = { controls };
    const body = JSON.stringify(payloadData);
    const response = yield apiRequest('api/v1/rescheduler/submit', { method: 'post', body });

    if (!response.error) {
      navigate('/reschedule/success');
    } else {
      return yield put(apiSuccess(setErrorMessage, { msg: 'Falló la carga.' }));
    }

    return yield put(submitReScheduleSuccess(response));
  });
} */

function* getTechniciansAndAvailabilitiesData() {
  yield takeLatest(
    getTechniciansAndAvailabilities,
    function* getTechniciansAndAvailabilitiesFunc(action) {
      const { selectedService, workshopId, modelId, kmId, diagnosisType, startDate, endDate } =
        action.payload;
      const socketId = localStorage.getItem('socketId');
      const response = yield apiRequest(
        `api/v1/scheduler/availabilities_and_technicians?selectedService=${selectedService}&socketId=${socketId}&workshopId=${workshopId}&modelId=${modelId}&kmId=${kmId}&diagnosisType=${diagnosisType}&startDate=${startDate}&endDate=${endDate}`,
        { method: 'get' },
      );

      if (response.error) {
        return yield put(
          apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
        );
      }

      return response;
    },
  );
}

function* getAvailabilitiesData() {
  yield takeLatest(getAvailabilities, function* getAvailabilitiesFunc(action) {
    const { selectedService, startDate, endDate, technicianBusinessPartnerId } = action.payload;
    const socketId = localStorage.getItem('socketId');
    const response = yield apiRequest(
      `api/v1/scheduler/technician_availabilities?socketId=${socketId}&selectedService=${selectedService}&startDate=${startDate}&endDate=${endDate}&technicianBusinessPartnerId=${technicianBusinessPartnerId}`,
      { method: 'get' },
    );

    if (response.error) {
      return yield put(toggleSchedulerError({ errorMsg: response.error }));
    }

    return response;
  });
}

export default function* SchedulerSaga() {
  yield spawn(getScheduleVoucherDocument);
  yield spawn(getUserVehicleData);
  yield spawn(getUserInfo);
  yield spawn(getDatesData);
  yield spawn(submitScheduleData);
  yield spawn(getUserVehicleDataWithConectaApiSaga);
  yield spawn(getTechniciansAndAvailabilitiesData);
  yield spawn(getAvailabilitiesData);
  yield spawn(submitReScheduleData);
}
