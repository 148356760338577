import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Snackbar } from '@mui/material';

function LoginSnackbars(props) {
  const { error, success, toggleError, toggleSuccess, errorMsg, successMsg } = props;

  const errorSnack = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={error}
      autoHideDuration={3000}
      onClose={toggleError}>
      <Alert onClose={toggleError} severity="error">
        {errorMsg}
      </Alert>
    </Snackbar>
  );

  const successSnack = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={success}
      autoHideDuration={3000}
      onClose={toggleSuccess}>
      <Alert onClose={toggleSuccess} severity="success">
        {successMsg}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      {errorSnack} {successSnack}
    </>
  );
}

LoginSnackbars.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  toggleError: PropTypes.func.isRequired,
  toggleSuccess: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
};

LoginSnackbars.defaultProps = {
  error: false,
  success: false,
  errorMsg: '',
  successMsg: '',
};

export default LoginSnackbars;
