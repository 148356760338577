/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import AppSaga from 'content/app.saga';
import AnnulmentSaga from 'content/features/annulmentSteps/annulmentSteps.saga';
import { LoginSaga, RecoverySaga, RegisterSaga } from 'content/features/authentication';
import ConfirmSaga from 'content/features/confirmCard/confirm.saga';
import { CounterSaga } from 'content/features/counter';
import { SchedulerSaga } from 'content/features/schedulerSteps';
import { UserTasksSaga } from 'content/features/tasks';
import WelcomeScreensSaga from 'content/features/welcomeScreens/welcomeScreens.saga';

export default function* MainSaga() {
  yield all([
    LoginSaga(),
    CounterSaga(),
    AppSaga(),
    UserTasksSaga(),
    RecoverySaga(),
    RegisterSaga(),
    SchedulerSaga(),
    WelcomeScreensSaga(),
    AnnulmentSaga(),
    ConfirmSaga(),
  ]);
}
