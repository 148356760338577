import * as React from 'react';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function NotEmailContactDialog(props) {
  const {
    open,
    handleClose,
    handleSetOpenNotEmailContactDialog,
    toggleEditDialog,
    selectedIndexContact,
  } = props;

  const handleClickCancelButton = () => {
    handleSetOpenNotEmailContactDialog(-1);
  };

  const handleClickAcceptButton = () => {
    handleSetOpenNotEmailContactDialog(selectedIndexContact);
    toggleEditDialog();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title" sx={SchedulerStepsStyles.dialogTitle}>
          Advertencia
        </DialogTitle>
        <DialogContent>
          Para poder seleccionar un contacto este debe de tener ingresado un email
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancelButton}>Cancelar</Button>
          <Button variant="contained" onClick={handleClickAcceptButton}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NotEmailContactDialog;
