const recoveryStyles = {
  card: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginTop: '5%',
  },
  image: {
    marginBottom: '2rem',
    // backgroundColor: '#212529',
  },
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
  },
  textfield: {
    marginTop: '10px',
  },
  button: {
    width: '120px',
    height: '36px',
    padding: '6px 16px',
    position: 'relative',
    margin: 'auto',
  },
  progress: {
    width: '24px !important',
    height: '24px !important',
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
  },
  alignCenter: {
    textAlign: 'center',
  },
};

export default recoveryStyles;
