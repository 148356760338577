import { createReducer } from '@reduxjs/toolkit';

import CounterActions from './counter.actions';

const CounterReducer = createReducer({ value: 0 }, (builder) => {
  builder
    .addCase(CounterActions.increment, (state) => {
      state.value += 1;
    })
    .addCase(CounterActions.decrement, (state) => {
      state.value -= 1;
    })
    .addCase(CounterActions.incrementByAmount, (state, action) => {
      state.value += action.payload.amount;
    });
});

export default CounterReducer;
