/* eslint-disable */
import * as React from 'react';

/* Material UI */
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    padding: '0px',
    width: '80px',
    left: '-40px',
    '@media (max-width:390px)': {
      left: '-35px',
      width: '70px',
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    background: theme.palette.primary.main,
    width: '100%',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.active && {
    backgroundColor: '#fff',
    color: 'black',
    border: '1px solid black',
    padding: '0px',
    boxSizing: 'border-box',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: '#fff',
    paddingRight: 0,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  return <ColorlibStepIconRoot ownerState={{ completed, active }}>{icon}</ColorlibStepIconRoot>;
}

function StepsMobile(props) {
  const { steps, activeStep } = props;

  return (
    <Stack sx={{ width: '100%' }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel key={label} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default StepsMobile;
