import React, { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import FormHelperText from '@mui/material/FormHelperText';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Hidden, TablePagination, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import ReScheduleMobileDialog from './ReScheduleMobileDialog';
import { closeAppointmentInfo, openAppointmentInfo } from '../schedulerSteps.actions';

/* import ScheduleMobileDialog from './ScheduleMobileDialog';
 */

const ticketLabels = [
  '',
  'ID Ticket',
  'Marca',
  'Modelo',
  'Versión',
  'Patente',
  'Tipo de servicio',
  'Sucursal',
  'Fecha',
];

const mobileLabels = ['	', 'ID Ticket', 'Fecha', 'Ver'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(172,188,227,0.20)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ResultsTable(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const labels = isMobile ? mobileLabels : ticketLabels;
  const { onChange, controls, errors } = props;

  const checked = (id) => controls.selectedReschedule.idTicket === id.idTicket;

  const appointments = useSelector((state) => state.welcomeScreen.appointments);
  const appointmentsTitle = useSelector((state) => state.welcomeScreen.appointmentsTitle);
  const appointmentInfo = useSelector((state) => state.schedulerSteps.controls.appointmentInfo);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortedAppointments = useMemo(() => {
    const arrCopy = [...appointments];
    return arrCopy.sort(
      (a, b) => new Date(b.dateWithoutFormat).getTime() - new Date(a.dateWithoutFormat).getTime(),
    );
  }, [appointments]);

  return (
    <div>
      <Typography color="primary" variant="h6" gutterBottom sx={SchedulerStepsStyles.title}>
        Seleccionar servicio
      </Typography>

      {appointmentsTitle !== '' && (
        <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.title}>
          {appointmentsTitle}
        </Typography>
      )}

      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead sx={SchedulerStepsStyles.tableHeader}>
            <StyledTableRow>
              {labels.map((row) => (
                <StyledTableCell key={row} align="center" sx={SchedulerStepsStyles.tableCell}>
                  {row}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedAppointments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((appointment) => (
                <StyledTableRow
                  key={appointment.idTicket}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="center" sx={{ padding: '0px' }}>
                    <Checkbox
                      checked={checked(appointment)}
                      onChange={() => onChange(appointment)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                    {appointment.idTicket}
                  </StyledTableCell>
                  <Hidden mdDown>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.brand}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.model}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.version}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.patent}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.serviceType}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                      {appointment.workshop}
                    </StyledTableCell>
                  </Hidden>
                  <StyledTableCell align="center" sx={SchedulerStepsStyles.tableValue}>
                    {moment.utc(appointment.serviceDate).local().format('DD-MM-YYYY - HH:mm')}
                  </StyledTableCell>
                  <Hidden mdUp>
                    <StyledTableCell align="center" sx={{ padding: '0px' }}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => dispatch(openAppointmentInfo(appointment))}>
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </StyledTableCell>
                  </Hidden>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedAppointments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isMobile ? '' : 'Resultados por página'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
          }
        />
      </TableContainer>
      <FormHelperText sx={{ mt: 2 }} error={!!errors.selectedReschedule}>
        {errors.selectedReschedule[0]}
      </FormHelperText>
      {appointmentInfo && (
        <ReScheduleMobileDialog
          open={appointmentInfo}
          handleClose={() => dispatch(closeAppointmentInfo())}
          appointment={appointmentInfo}
        />
      )}
    </div>
  );
}

export default ResultsTable;
